import React from "react";
import brandlogo from '../../assets/images/homepage-images/brandlogo.webp';

const Loader = () => (
    <div className="load-container">
        {/* <div className="linespinner"></div> */}
        <div>
            <img src={brandlogo} className="brand" />
        </div>
    </div>
);

export default Loader;
