import React, { useEffect, useState } from 'react';
import samsung from "../assets/images/homepage-images/samsung.png";
import havells from "../assets/images/homepage-images/havells.png";
import caterpillar from "../assets/images/homepage-images/caterpillar.png";
import luminous from "../assets/images/homepage-images/luminous.png";
import philips from "../assets/images/homepage-images/philips.png";
import ashokleyland from "../assets/images/homepage-images/ashokleyland.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SERVICE_API } from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function RequestDemo() {
    const data = [
        {
          id: "1",
          name: "AR/VR",
        },
        {
          id: "2",
          name: "Web Virtual Showroom",
        },
        {
          id: "3",
          name: "Kiosk",
        },
        {
          id: "4",
          name: "Print AR",
        },
        {
            id: "5",
            name: "LENS",
          }
      ];
    const [firstName , setfirstName] = useState('');
    const [lastName , setlastName] = useState('');
    const [email , setEmail] = useState('');
    const [mobileNumber , setmobileNumber] = useState('');
    const [country , setcountry] = useState('India');
    // const [subject , setSubject] = useState('');
    const [commentTextArea , setcommentTextArea] = useState('');
    const [products , setProducts] = useState(data);

    const successToast = () => toast.success("Thanks for your interest! We will be in touch with you shortly.", {
		position: toast.POSITION.TOP_RIGHT
	});
    

    var ProductSettings = {
        infinite: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    useEffect(() => {
        $(".navbar").css("background", "#fff");
        $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                btn.addClass("show");
            }
            else if (window.pageYOffset < 300) {
                btn.removeClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
        };
        window.addEventListener('scroll', handleScroll);

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });



    }, []);
    const formSubmit = (event) => {
        event.preventDefault();

        const final = products.map(item=>item.name)
        const name=final.join(', ');
        var formdata = new FormData();
        formdata.append("firstName",firstName);
        formdata.append("lastName", lastName);
        formdata.append("email", email);
        formdata.append("mobileNumber", mobileNumber);
        formdata.append("products", name);
        formdata.append("country", country);
        // formdata.append("subject", subject);
        formdata.append("commentTextArea", commentTextArea);
        event.target.reset();
        
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
        //   "https://augray.us:3000/api/v1/email/send-demo",
        `${SERVICE_API}api/v1/email/send-demo`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result), successToast())
          .catch((error) => console.log("error", error));
    }


    const handleCh = (item) => {
        let tempPeopleInfo = [...products]
        if (tempPeopleInfo.some((people) => people.id === item.id)) {
          tempPeopleInfo = tempPeopleInfo.filter((people) => people.id == item.id);
        } else {
          tempPeopleInfo.push(item);
        }
        setProducts(tempPeopleInfo)
      };
    return (
        <>
        <Helmet>
            <title>OneXR - Request for Demo</title>
            <meta name="description" content="Our experts will understand your business and assist you in knowing how OneXR can help. We'll also answer all your queries and help you have an in-depth ..." />
        </Helmet>
            <div className="warpper1 fadein">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="request-mainpage">
                    <div className="req-head"> <span>REQUEST FOR DEMO</span> </div>
                    <div className="req-row row p-5">
                        <div className="col-lg-4 col-md-4 col-12  req-work "> 
                            <span>See How it Works </span>
                            <p className='requiredIns'>Fields marked with an <b>*</b> are required</p>
                            <form onSubmit={(event)=>formSubmit(event)} className='mt-4' id='demoForm'>
                                <div className="row p-2 mb-4">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>First Name <b>*</b></label>
                                        <input type="text" name="firstName" onChange={(e) => setfirstName(e.target.value)} className="form-control"  required/>

                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Last Name  <b>*</b></label>
                                        <input type="text" name="lastName" onChange={(e) => setlastName(e.target.value)} className="form-control"  required/>

                                    </div>
                                </div>
                                <div className="row p-2 mb-4">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Email Address <b>*</b></label>
                                        <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} className="form-control" required />

                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 arr_none">
                                        <label>Phone <b>*</b></label>
                                        <input type="number" name="phone" onChange={(e) => setmobileNumber(e.target.value)} className="form-control" placeholder="" maxLength="10" required/>

                                    </div>
                                </div>
                                <div className="row p-2 mb-4">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="mb-2">Country </label>
                                        <select id="selectCountry" name="selectCountry" onChange={(e) => setcountry(e.target.value)} className="form-control" required>
                                            <option value="">- Select Country -</option>
                                            <option value="AF">Afghanistan</option>
                                            <option value="AL">Albania</option>
                                            <option value="DZ">Algeria</option>
                                            <option value="AS">American Samoa</option>
                                            <option value="AD">Andorra</option>
                                            <option value="AO">Angola</option>
                                            <option value="AI">Anguilla</option>
                                            <option value="AQ">Antarctica</option>
                                            <option value="AG">Antigua And Barbuda</option>
                                            <option value="AR">Argentina</option>
                                            <option value="AM">Armenia</option>
                                            <option value="AW">Aruba</option>
                                            <option value="AU">Australia</option>
                                            <option value="AT">Austria</option>
                                            <option value="AZ">Azerbaijan</option>
                                            <option value="BS">Bahamas</option>
                                            <option value="BH">Bahrain</option>
                                            <option value="BD">Bangladesh</option>
                                            <option value="BB">Barbados</option>
                                            <option value="BY">Belarus</option>
                                            <option value="BE">Belgium</option>
                                            <option value="BZ">Belize</option>
                                            <option value="BJ">Benin</option>
                                            <option value="BM">Bermuda</option>
                                            <option value="BT">Bhutan</option>
                                            <option value="BO">Bolivia</option>
                                            <option value="BA">Bosnia And Herzegowina</option>
                                            <option value="BW">Botswana</option>
                                            <option value="BV">Bouvet Island</option>
                                            <option value="BR">Brazil</option>
                                            <option value="IO">British Indian Ocean Territory</option>
                                            <option value="BN">Brunei Darussalam</option>
                                            <option value="BG">Bulgaria</option>
                                            <option value="BF">Burkina Faso</option>
                                            <option value="BI">Burundi</option>
                                            <option value="KH">Cambodia</option>
                                            <option value="CM">Cameroon</option>
                                            <option value="CA">Canada</option>
                                            <option value="CV">Cape Verde</option>
                                            <option value="KY">Cayman Islands</option>
                                            <option value="CF">Central African Republic</option>
                                            <option value="TD">Chad</option>
                                            <option value="CL">Chile</option>
                                            <option value="CN">China</option>
                                            <option value="CX">Christmas Island</option>
                                            <option value="CC">Cocos (Keeling) Islands</option>
                                            <option value="CO">Colombia</option>
                                            <option value="KM">Comoros</option>
                                            <option value="CG">Congo</option>
                                            <option value="CD">Congo, The Democratic Republic Of The</option>
                                            <option value="CK">Cook Islands</option>
                                            <option value="CR">Costa Rica</option>
                                            <option value="CI">Cote D'Ivoire</option>
                                            <option value="HR">Croatia (Local Name: Hrvatska)</option>
                                            <option value="CU">Cuba</option>
                                            <option value="CY">Cyprus</option>
                                            <option value="CZ">Czech Republic</option>
                                            <option value="DK">Denmark</option>
                                            <option value="DJ">Djibouti</option>
                                            <option value="DM">Dominica</option>
                                            <option value="DO">Dominican Republic</option>
                                            <option value="EC">Ecuador</option>
                                            <option value="EG">Egypt</option>
                                            <option value="SV">El Salvador</option>
                                            <option value="GQ">Equatorial Guinea</option>
                                            <option value="ER">Eritrea</option>
                                            <option value="EE">Estonia</option>
                                            <option value="ET">Ethiopia</option>
                                            <option value="FO">Faroe Islands</option>
                                            <option value="FJ">Fiji</option>
                                            <option value="FI">Finland</option>
                                            <option value="FR">France</option>
                                            <option value="FX">France, Metropolitan</option>
                                            <option value="GF">French Guiana</option>
                                            <option value="PF">French Polynesia</option>
                                            <option value="TF">French Southern Territories</option>
                                            <option value="GA">Gabon</option>
                                            <option value="GM">Gambia</option>
                                            <option value="GE">Georgia</option>
                                            <option value="DE">Germany</option>
                                            <option value="GH">Ghana</option>
                                            <option value="GI">Gibraltar</option>
                                            <option value="GR">Greece</option>
                                            <option value="GL">Greenland</option>
                                            <option value="GD">Grenada</option>
                                            <option value="GP">Guadeloupe</option>
                                            <option value="GU">Guam</option>
                                            <option value="GT">Guatemala</option>
                                            <option value="GN">Guinea</option>
                                            <option value="GW">Guinea-Bissau</option>
                                            <option value="GY">Guyana</option>
                                            <option value="HT">Haiti</option>
                                            <option value="HM">Heard And Mc Donald Islands</option>
                                            <option value="VA">Holy See (Vatican City State)</option>
                                            <option value="HN">Honduras</option>
                                            <option value="HK">Hong Kong</option>
                                            <option value="HU">Hungary</option>
                                            <option value="IS">Iceland</option>
                                            <option value="IN" selected="selected">India</option>
                                            <option value="ID">Indonesia</option>
                                            <option value="IR">Iran (Islamic Republic Of)</option>
                                            <option value="IQ">Iraq</option>
                                            <option value="IE">Ireland</option>
                                            <option value="IL">Israel</option>
                                            <option value="IT">Italy</option>
                                            <option value="JM">Jamaica</option>
                                            <option value="JP">Japan</option>
                                            <option value="JO">Jordan</option>
                                            <option value="KZ">Kazakhstan</option>
                                            <option value="KE">Kenya</option>
                                            <option value="KI">Kiribati</option>
                                            <option value="KP">Korea, Democratic People's Republic Of</option>
                                            <option value="KR">Korea, Republic Of</option>
                                            <option value="KW">Kuwait</option>
                                            <option value="KG">Kyrgyzstan</option>
                                            <option value="LA">Lao People's Democratic Republic</option>
                                            <option value="LV">Latvia</option>
                                            <option value="LB">Lebanon</option>
                                            <option value="LS">Lesotho</option>
                                            <option value="LR">Liberia</option>
                                            <option value="LY">Libyan Arab Jamahiriya</option>
                                            <option value="LI">Liechtenstein</option>
                                            <option value="LT">Lithuania</option>
                                            <option value="LU">Luxembourg</option>
                                            <option value="MO">Macau</option>
                                            <option value="MK">Macedonia, Former Yugoslav Republic Of</option>
                                            <option value="MG">Madagascar</option>
                                            <option value="MW">Malawi</option>
                                            <option value="MY">Malaysia</option>
                                            <option value="MV">Maldives</option>
                                            <option value="ML">Mali</option>
                                            <option value="MT">Malta</option>
                                            <option value="MH">Marshall Islands</option>
                                            <option value="MQ">Martinique</option>
                                            <option value="MR">Mauritania</option>
                                            <option value="MU">Mauritius</option>
                                            <option value="YT">Mayotte</option>
                                            <option value="MX">Mexico</option>
                                            <option value="FM">Micronesia, Federated States Of</option>
                                            <option value="MD">Moldova, Republic Of</option>
                                            <option value="MC">Monaco</option>
                                            <option value="MN">Mongolia</option>
                                            <option value="ME">Montenegro</option>
                                            <option value="MS">Montserrat</option>
                                            <option value="MA">Morocco</option>
                                            <option value="MZ">Mozambique</option>
                                            <option value="MM">Myanmar</option>
                                            <option value="NA">Namibia</option>
                                            <option value="NR">Nauru</option>
                                            <option value="NP">Nepal</option>
                                            <option value="NL">Netherlands</option>
                                            <option value="AN">Netherlands Antilles</option>
                                            <option value="NC">New Caledonia</option>
                                            <option value="NZ">New Zealand</option>
                                            <option value="NI">Nicaragua</option>
                                            <option value="NE">Niger</option>
                                            <option value="NG">Nigeria</option>
                                            <option value="NU">Niue</option>
                                            <option value="NF">Norfolk Island</option>
                                            <option value="MP">Northern Mariana Islands</option>
                                            <option value="NO">Norway</option>
                                            <option value="OM">Oman</option>
                                            <option value="PK">Pakistan</option>
                                            <option value="PW">Palau</option>
                                            <option value="PA">Panama</option>
                                            <option value="PG">Papua New Guinea</option>
                                            <option value="PY">Paraguay</option>
                                            <option value="PE">Peru</option>
                                            <option value="PH">Philippines</option>
                                            <option value="PN">Pitcairn</option>
                                            <option value="PL">Poland</option>
                                            <option value="PT">Portugal</option>
                                            <option value="PR">Puerto Rico</option>
                                            <option value="QA">Qatar</option>
                                            <option value="RE">Reunion</option>
                                            <option value="RO">Romania</option>
                                            <option value="RU">Russian Federation</option>
                                            <option value="RW">Rwanda</option>
                                            <option value="KN">Saint Kitts And Nevis</option>
                                            <option value="LC">Saint Lucia</option>
                                            <option value="VC">Saint Vincent And The Grenadines</option>
                                            <option value="WS">Samoa</option>
                                            <option value="SM">San Marino</option>
                                            <option value="ST">Sao Tome And Principe</option>
                                            <option value="SA">Saudi Arabia</option>
                                            <option value="SN">Senegal</option>
                                            <option value="RS">Serbia</option>
                                            <option value="SC">Seychelles</option>
                                            <option value="SL">Sierra Leone</option>
                                            <option value="SG">Singapore</option>
                                            <option value="SK">Slovakia (Slovak Republic)</option>
                                            <option value="SI">Slovenia</option>
                                            <option value="SB">Solomon Islands</option>
                                            <option value="SO">Somalia</option>
                                            <option value="ZA">South Africa</option>
                                            <option value="GS">South Georgia, South Sandwich Islands</option>
                                            <option value="ES">Spain</option>
                                            <option value="LK">Sri Lanka</option>
                                            <option value="SH">St. Helena</option>
                                            <option value="PM">St. Pierre And Miquelon</option>
                                            <option value="SD">Sudan</option>
                                            <option value="SR">Suriname</option>
                                            <option value="SJ">Svalbard And Jan Mayen Islands</option>
                                            <option value="SZ">Swaziland</option>
                                            <option value="SE">Sweden</option>
                                            <option value="CH">Switzerland</option>
                                            <option value="SY">Syrian Arab Republic</option>
                                            <option value="TW">Taiwan</option>
                                            <option value="TJ">Tajikistan</option>
                                            <option value="TZ">Tanzania, United Republic Of</option>
                                            <option value="TH">Thailand</option>
                                            <option value="TL">Timor-Leste (East Timor)</option>
                                            <option value="TG">Togo</option>
                                            <option value="TK">Tokelau</option>
                                            <option value="TO">Tonga</option>
                                            <option value="TT">Trinidad And Tobago</option>
                                            <option value="TN">Tunisia</option>
                                            <option value="TR">Turkey</option>
                                            <option value="TM">Turkmenistan</option>
                                            <option value="TC">Turks And Caicos Islands</option>
                                            <option value="TV">Tuvalu</option>
                                            <option value="UG">Uganda</option>
                                            <option value="UA">Ukraine</option>
                                            <option value="AE">United Arab Emirates</option>
                                            <option value="GB">United Kingdom</option>
                                            <option value="US">United States</option>
                                            <option value="UM">United States Minor Outlying Islands</option>
                                            <option value="UY">Uruguay</option>
                                            <option value="UZ">Uzbekistan</option>
                                            <option value="VU">Vanuatu</option>
                                            <option value="VE">Venezuela</option>
                                            <option value="VN">Viet Nam</option>
                                            <option value="VG">Virgin Islands (British)</option>
                                            <option value="VI">Virgin Islands (U.S.)</option>
                                            <option value="WF">Wallis And Futuna Islands</option>
                                            <option value="EH">Western Sahara</option>
                                            <option value="YE">Yemen</option>
                                            <option value="YU">Yugoslavia</option>
                                            <option value="ZM">Zambia</option>
                                            <option value="ZW">Zimbabwe</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="row selectProductRow p-2 mb-4">
                                    <div className="col-12">
                                        <label className=" mb-2" >Which Products are you interested in? <b>*</b></label>
                                        {data.map((item) => {
                                            return (<><ul><label><input type="checkbox" name="product" onChange={() => {handleCh(item)}} className="pro-checkbox" value={products} />{item.name}</label></ul></>
                                                );
                                        })}
                                        {/* <ul><label><input type="checkbox" name="product[]" onChange={(e) => setProducts(e.target.value)} className="pro-checkbox" value="AR/VR" />AR/VR</label></ul>
                                        <ul><label><input type="checkbox" name="product[]" onChange={(e) => setProducts(e.target.value)} className="pro-checkbox" value="Web Virtual Showroom" />Web Virtual Showroom</label></ul>
                                        <ul><label><input type="checkbox" name="product[]" onChange={(e) => setProducts(e.target.value)} className="pro-checkbox" value="Kiosk" />Kiosk</label></ul>
                                        <ul><label><input type="checkbox" name="product[]" onChange={(e) => setProducts(e.target.value)} className="pro-checkbox" value="Print AR" />Print AR</label></ul>
                                        <ul><label><input type="checkbox" name="product[]" onChange={(e) => setProducts(e.target.value)} className="pro-checkbox" value="LENS" />LENS</label></ul> */}
                                    </div>
                                </div>
                                <div className="row p-2 mb-4">
                                    <div className="col-12">
                                        <label>Write your query or feedback here <b>*</b></label>
                                        <textarea type="text" name="comments" onChange={(e) => setcommentTextArea(e.target.value)} className="form-control" placeholder="" style={{minHeight:"200px"}} required></textarea>
                                    </div>
                                </div>
                                <div className="pt-2 pb-5 ps-4">
                                    <button type="submit" name="submit" className="btn btn-dark" id='requestSubmit' style={{padding:"2% 6%"}}>SUBMIT</button>
                                    <ToastContainer
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 pt-3  req-demo">
                            <div>
                                <h3>One-On-One Demo for you!</h3>
                                <p className="fadeindown">Our experts will understand your business and assist you in knowing how OneXR can help. We'll also answer all your queries and help you have an in-depth understanding of our product. Please use the description to mention any special requirements.</p>
                            </div>
                            <hr className='requestLine'></hr>
                            <div>
                                <h3 className="pt-5 pb-5">World's most successful companies use our products</h3>
                                <div className="req-carousel p-2 mb-4">
                                    <Slider {...ProductSettings}>
                                        <div className="d-flex justify-content-center"> <img src={samsung} /> </div>
                                        <div className="d-flex justify-content-center"> <img src={havells} /> </div>
                                        <div className="d-flex justify-content-center"> <img src={caterpillar} /> </div>
                                        <div className="d-flex justify-content-center"> <img src={luminous} /> </div>
                                        <div className="d-flex justify-content-center"> <img src={philips} /> </div>
                                        <div className="d-flex justify-content-center"> <img src={ashokleyland} /> </div>
                                    </Slider>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RequestDemo;

