import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import brandlogo from "../../assets/images/homepage-images/brandlogo.webp";

function TopBar() {
  const navigate = useNavigate();

  const location = useLocation();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    var header = document.getElementById("navDiv");
    var btns = header.getElementsByClassName("nav-link");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
        }
        this.className += " active";
      });
    }

    var dropDownheader = document.getElementById("dropdownDiv");
    var dropdownBtns = dropDownheader.getElementsByClassName("dropdown-item");
    for (var i = 0; i < dropdownBtns.length; i++) {
      dropdownBtns[i].addEventListener("click", function () {
        var currentDropItem = document.getElementsByClassName("activeDropdown");
        if (currentDropItem.length > 0) {
          currentDropItem[0].className = currentDropItem[0].className.replace(
            " activeDropdown",
            ""
          );
        }
        this.className += " activeDropdown";
      });
    }
  }, []);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  function pageRedirect(link) {
    if (link == "/requestDemo") {
      document.title = "OneXR - Request for Demo";
    } else if (link == "/contact") {
      document.title = "OneXR - Contact Us";
    } else if (link == "/resources") {
      document.title = "OneXR - Resources";
    } else if (link == "/company") {
      document.title = "OneXR - Company";
    } else if (link == "/lens") {
      document.title = "OneXR - Lens";
    } else if (link == "/print-ar") {
      document.title = "OneXR - Print AR";
    } else if (link == "/kiosk") {
      document.title = "OneXR - Kiosk solution";
    } else if (link == "/web-virtual-showroom") {
      document.title = "OneXR - Web Virtual Showroom";
    } else if (link == "/ar-vr") {
      document.title = "OneXR - AR/VR Solution";
    } else if (link == "/coach") {
      document.title = "OneXR - Coach";
    } else if (link == "/swag") {
      document.title = "OneXR - Gifts";
    }  else if (link == "/") {
      document.title = "OneXR - Home";
    } else {
      document.title = "OneXR";
    }
  }

  return (
    <>
      <div className="topbar">
        <nav
          className="navbar navbar-expand-lg navbar-light"
          style={{ padding: "0 7% 0 10%" }}
        >
          <div className="container">
          <a className="navbar-brand" onClick={() => pageRedirect("/")}>
            <Link
              className={"nav-link" + (url === "/" ? " active" : "")}
              aria-current="page"
              to="/"
            >
              <img src={brandlogo} className="logo" />
            </Link>
          </a>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBothOptions"
              aria-controls="offcanvasWithBothOptions"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-start"
              tabIndex="-1"
              id="offcanvasWithBothOptions"
              data-bs-backdrop="true"
              aria-labelledby="offcanvasWithBothOptionsLabel"
            >
              <div className="offcanvas-header">
                <h5
                  className="offcanvas-title"
                  id="offcanvasWithBothOptionsLabel"
                >
                  <Link to="/">
                    <img src={brandlogo} className="logo" />
                  </Link>
                </h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav ms-auto" id="navDiv">
                  <li className="nav-item" onClick={() => pageRedirect("/")} data-bs-dismiss="offcanvas">
                    {" "}
                    <Link
                      className={"nav-link" + (url === "/" ? " active" : "")}
                      aria-current="page"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Products
                    </a>
                    <ul className="dropdown-menu" id="dropdownDiv">
                      <li
                        className="dropdown-item"
                        onClick={() => pageRedirect("/ar-vr")} data-bs-dismiss="offcanvas"
                      >
                        <Link
                          className={
                            "dropdown-item" +
                            (url === "/ar-vr" ? " active" : "")
                          }
                          to="/ar-vr"
                        >
                          AR/VR Solution
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => pageRedirect("/web-virtual-showroom")} data-bs-dismiss="offcanvas"
                      >
                        <Link
                          className={
                            "dropdown-item" +
                            (url === "/web-virtual-showroom" ? " active" : "")
                          }
                          to="/web-virtual-showroom"
                        >
                          Web Virtual Showroom
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => pageRedirect("/kiosk")} data-bs-dismiss="offcanvas"
                      >
                        {" "}
                        <Link
                          className={
                            "dropdown-item" +
                            (url === "/kiosk" ? " active" : "")
                          }
                          to="/kiosk"
                        >
                          Kiosk solution
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      {/* <li
                        className="dropdown-item"
                        onClick={() => pageRedirect("/print-ar")} data-bs-dismiss="offcanvas"
                      >
                        {" "}
                        <Link
                          className={
                            "dropdown-item" +
                            (url === "/print-ar" ? " active" : "")
                          }
                          to="/print-ar"
                        >
                          Print AR
                        </Link>
                      </li> */}
                      <li
                        className="dropdown-item"
                        onClick={() => pageRedirect("/swag")} data-bs-dismiss="offcanvas"
                      >
                        {" "}
                        <Link
                          className={
                            "dropdown-item" +
                            (url === "/swag" ? " active" : "")
                          }
                          to="/swag"
                        >
                          Gifts
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => pageRedirect("/lens")} data-bs-dismiss="offcanvas"
                      >
                        <Link
                          className={
                            "dropdown-item" + (url === "/lens" ? " active" : "")
                          }
                          to="/lens"
                        >
                          Lens
                        </Link>
                      </li>
                       {/* <li>
                        <hr className="dropdown-divider" />
                      </li>
                     <li
                        className="dropdown-item"
                        onClick={() => pageRedirect("/coach")} data-bs-dismiss="offcanvas"
                      >
                        <Link
                          className={
                            "dropdown-item" + (url === "/coach" ? " active" : "")
                          }
                          to="/coach"
                        >
                          OneXR Coach
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => pageRedirect("/company")} data-bs-dismiss="offcanvas"
                  >
                    {" "}
                    <Link
                      className={
                        "nav-link" + (url === "/company" ? " active" : "")
                      }
                      to="/company"
                    >
                      Company
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => pageRedirect("/resources")} data-bs-dismiss="offcanvas"
                  >
                    {" "}
                    <Link
                      className={
                        "nav-link" + (url === "/resources" ? " active" : "")
                      }
                      to="/resources"
                    >
                      Resources
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => pageRedirect("/contact")} data-bs-dismiss="offcanvas"
                  >
                    {" "}
                    <Link
                      className={
                        "nav-link" + (url === "/contact" ? " active" : "")
                      }
                      to="/contact"
                    >
                      Contact Us
                    </Link>{" "}
                  </li>
                  <li className="nav-item" onClick={() => pageRedirect("/requestDemo")} data-bs-dismiss="offcanvas">
                    <Link className={"nav-link" + (url === "/requestDemo" ? " active" : "")}to="/requestDemo">Request for Demo</Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <a id="button">
        <i className="bi bi-arrow-up-square-fill"></i>
      </a>
      
    </>
  );
}

export default TopBar;
