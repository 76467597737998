import React, { useEffect, useState } from 'react';

import ar_vr_mainimg from "../../assets/images/ar-vr-images/ar-vr-mainimg.png";
import Group263 from "../../assets/images/homepage-images/Group263.svg";
import Group264 from "../../assets/images/homepage-images/Group264.svg";
import Group265 from "../../assets/images/homepage-images/Group265.svg";
import Group266 from "../../assets/images/homepage-images/Group266.svg";
import products1 from "../../assets/images/ar-vr-images/products-1.png";
import products2 from "../../assets/images/ar-vr-images/products-2.png";
import products3 from "../../assets/images/ar-vr-images/products-3.png";
import solution_1 from "../../assets/images/ar-vr-images/solution-1.png";
import solution_2 from "../../assets/images/ar-vr-images/solution-2.png";
import solution_3 from "../../assets/images/ar-vr-images/solution-3.png";
import solution_4 from "../../assets/images/ar-vr-images/solution-4.png";
import solution_5 from "../../assets/images/ar-vr-images/solution-5.png";
import girlwithocolus from "../../assets/images/ar-vr-images/girlwithocolus.png";
import virtual_bg from "../../assets/images/virtualshowroom-images/virtual-bg.jpg";
import dots from "../../assets/images/ar-vr-images/dots.png";
import ar_vr_bg from "../../assets/images/ar-vr-images/ar-vr_bg.jpeg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function ARVRProduct() {

    var ProductSettings = {
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 10000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };
        window.addEventListener('scroll', handleScroll);

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);

    return (
        <>

        <Helmet>
            <title>OneXR - AR/VR Solution</title>
            <meta name="description" content="Reduce Sales cycle time with Interactive Virtual Demos! Let your prospects preview products in 360-Degree in real size in any environment instantly! Engage all ..." />
        </Helmet>
            <div className="warpper1 fadein">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="ar-vr-main" style={{ backgroundImage: `url(${virtual_bg})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-4 p-5 ">
                            <div className=" mt-5">
                                <h1>OneXR</h1>
                                <h2> AR/VR Solution</h2>
                                <p> Is your brand ready to revolutionise the way customers get insights into your products? Our AR/VR solution gives life to your brand, enabling the user to experience your products in futuristic reality!</p>
                            </div>
                        </div>
                        <div className=" col-12 col-lg-6 ">
                            <img src={ar_vr_mainimg} className="" />
                        </div>
                    </div>
                </div>

                <div className="benefits row m-0 pt-2 pb-2">
                    <div className="col-lg-3 col-md-3">
                        <h1>Beneficiaries of <br />OneXR</h1> </div>
                    <div className=" col-lg-9 col-md-9">
                        <div className="carousel grab">
                            <Slider {...ProductSettings}>
                                <div> <img src={Group263} />
                                    <p className="icon-para">Automotive</p>
                                </div>
                                <div> <img src={Group264} />
                                    <p className="icon-para">Construction</p>
                                </div>
                                <div> <img src={Group265} />
                                    <p className="icon-para">Maintanance & Repair</p>
                                </div>
                                <div> <img src={Group266} />
                                    <p className="icon-para">Manufatoring</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="ar-vr-products">
                    <div className="content-row1">
                        <div className="content1">
                            <h3>OneXR AR/VR Solution</h3>
                            <h2>Transform your traditional Sales and employees training with the OneXR AR/VR Solution!</h2> </div>
                    </div>
                    <div className="card-row row container ar_vr_solutionCol">
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={products1} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Redefine product demonstration</h1>
                                    <p>Showcase your product in a <b>virtual 3D environment</b> and demonstrate its features in minutes! Help customers <b>visualize, experience products, make them knowledgeable!</b> </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={products2} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>
                                        Train employees in
                                        half the time!</h1>
                                    <p><b>Train employees remotely in a more immersive and engaging</b> way using AR/VR technology. 3D Graphical <b>step-by-step</b> instructions on every product achieved! </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={products3} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Reduce Sales cycle time with Interactive Virtual Demos!</h1>
                                    <p>Let your prospects preview products in <b>360-Degree in real size</b> in any environment instantly! <b>Engage</b> all stakeholders and experts <b>virtually demonstrating with VR and AR technologies!</b> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <hr style={{margin:"0 7%"}}/>
                </div>

                <div className="ar-vr-solution">
                    <div className="content-row2">
                        <div className="content2">
                            <h3>OneXR AR/VR Solution</h3>
                            <h1>This isn't just a solution but a virtual sales team in your pockets</h1> </div>
                    </div>
                    <div className="solution-row row  container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_1} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Engaging Product Visualizations</h2>
                                <p className="pro-p">Most of the time businesses end up spending huge on expensive trade  shows and events to reach out to their prospects to showcase their products and brand.</p> <p className="pro-p">Let your prospects go through the products just like that  with Augmented and Virtual Reality
                                    solutions. Provide  immersive Product demonstration experiences to your customers with  high-definition
                                    3D animations and real-life virtual product demonstrations. Adapting the New-Gen solutions and dynamic AR/VR solutions works wonders for your business by  increasing your customer engagement and experience!</p>
                                </div>
                        </div>
                    </div>
                    <div className="solution-row row  container">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Increase conversions with an X-Ray view!</h2>
                                <p className="pro-p">Every business want their  sales cycle to be minimum and their prospects to take  quick buying decision, and to achieve that, clients need to get a clear understanding of the product!</p> <p className="pro-p">Give your prospects the ease of demonstrating the products just like that with the power of AR/VR solutions. Augmented and Virtual Reality solution enable your prospects to have a  quick detailed overview of the products  hands-free and can have immersive product demos gaining  % more on user engagement, lead conversion, and improved brand recall! </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_2} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                    </div>
                    <div className="solution-row row  container">
                        <div className="img-column col-12 col-lg-6 " style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_3} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Improve Demos with deeper customer insights!</h2>
                                <p className="pro-p"> Reduce tough-hard explanations and enable the customers to experience the joy of understanding with  simple and effective New-Gen technologies with the OneXR AR/VR solution.</p> <p className="pro-p">Marketers feel difficult to strategize their plan or make it  data driven. Assist your marketers and your sales representatives with the live tracks  and advanced analytics through CMS, capturing details of each demo session giving insights on your  client's interaction and engagement with your product. Each entry is worthy, miss no data and have  quick conversions reaching the target audience on the go!</p>
                            </div>
                        </div>
                    </div>
                    <div className="solution-row row  container">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Provide an immersive and interactive experience
                                </h2>
                                <p className="pro-p">Maintaining a  lively pipeline is one of the tough things for the sales reps! And with traditional methods of marketing the  prospect ends up becoming cold.</p> <p className="pro-p"> Show your products in  real size with high-quality rendering,
                                        enabling prospects to engage, and explore with products interactively using AR/VR technologies. Guide customers to experience a lively 3D AR/VR platform  anywhere and anytime to help maintain a lively pipeline. Let your prospects think and decide what they want. Be the change you want to push in your businesses with our seamless OneXR! </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_4} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                    </div>
                    <div className="solution-row row  container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_5} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Create a Training module in AR/VR making employee training intuitive</h2>
                                <p className="pro-p">Employees are expected to get a prior understanding and a deep knowledge about the product, before getting hands on experience. Reading and byhearting the physical written text or presentations won't help much!</p> <p className="pro-p"> Engage your employees involved with the  interactive
                                        3D training modules to get a deeper understanding of the modules and enable them to  retain the concepts longer. The <strong>dynamic 3D modules</strong> grants an easy understanding, enabling the workers to excel and work smarter than before!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-bg">
                    <div className="contact-expo ar_vr_expo">
                        <div className="row " style={{ backgroundImage: `url(${ar_vr_bg})` }}>
                            <div className="col-12 col-lg-4 ">
                                <h4>Should you deploy OneXR?</h4>
                                <h3>The answer is a resounding YES!</h3>
                                <div className='ar-vr-expo-content'><i className="bi bi-check2"></i><p><b>Eliminate</b> the need to carry products, and other bulky items on-site</p></div>
                                <div className='ar-vr-expo-content'><i className="bi bi-check2"></i><p><b>Improved Retention, Interest, and Recall</b> (IRIR) for your product</p></div>
                                <div className='ar-vr-expo-content'><i className="bi bi-check2"></i><p>A brand new marketing <b>innovation</b></p></div>
                                <div className='ar-vr-expo-content'><i className="bi bi-check2"></i><p>A <b>one-of-a-kind</b> solution</p></div>
                                <div className="expoBtnDiv"><Link to="/requestDemo" style={{width:"45%"}}><i className="bi bi-check2"></i> Request Quote</Link></div>
                            </div>
                            <div className="col-12 col-lg-4 "> <img src={girlwithocolus} className="" /> </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default ARVRProduct;
