
import React, { useEffect, useState, useRef } from 'react';


import WebarFrame1 from "../assets/images/webarImage/chart1.png";
import WebarFrame2 from "../assets/images/webarImage/chart2.png";
import WebarFrame3 from "../assets/images/webarImage/chart3.png";
import worldmap from '../assets/images/homepage-images/worldmap.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';
import { ToastContainer, toast } from 'react-toastify';
import { SERVICE_API } from '../config';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import AOS from 'aos';


function WebAR() {

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [commentTextArea, setcommentTextArea] = useState('');
    const contactRef = useRef(null);
    const tcoRef = useRef(null);
    const capacityRef = useRef(null);
    const energyRef = useRef(null);

    const scrollto = (pageLink) => {
        if(pageLink == 'contact'){
            contactRef.current?.scrollIntoView({
                behavior: "smooth",
                nearest: "block",
               
              });
        } else  if(pageLink == 'tco'){
            tcoRef.current?.scrollIntoView({
                behavior: "smooth",
                nearest: "start"
              });
        } else  if(pageLink == 'energy'){
            energyRef.current?.scrollIntoView({
                behavior: "smooth",
                nearest: "block"
              });
        } else  if(pageLink == 'capacity'){
            capacityRef.current?.scrollIntoView({
                behavior: "smooth",
                nearest: "block"
              });
        }
       
    }
    const successToast = () => toast.success("Thanks for contact us! We will be in touch with you shortly.", {
        position: toast.POSITION.TOP_RIGHT
    });

    const formSubmit = (event) => {
        event.preventDefault();
        console.log("string");
        var formdata = new FormData();
        formdata.append("firstName", firstName);
        formdata.append("lastName", lastName);
        formdata.append("email", email);
        formdata.append("subject", subject);
        formdata.append("commentTextArea", commentTextArea);
        event.target.reset();

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(
            // "https://augray.us:3000/api/v1/email/send-demo",

            `${SERVICE_API}api/v1/email/send-expo`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => console.log(result), successToast())
            .catch((error) => console.log("error", error));
    }
    useEffect(() => {
        const path = window.location.href;
        const pageLink = path.slice(path.indexOf("#") + 1);
        setTimeout(() =>   scrollto(pageLink) , 777)
    }, [contactRef,contactRef]);

    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);



    return (
        <>
            <div className="warpper1">
                <a id="button"><i className="fa-solid fa-angle-up"></i></a>

                <div>
                    <div  className='webarContainerColumn' style={{ backgroundColor: "#d4bcec36" }}>
                        <div ref={capacityRef}>
                            <div className='centerDiv w_h_100' style={{ minHeight: '45vh' }}><img src={WebarFrame1} className="webarFramePic" /></div>
                        </div>
                    </div>
                    <div className='webarContainerColumn' style={{ backgroundColor: "#b4f1a236" }}>
                        <div ref={energyRef} >
                            <div className='centerDiv w_h_100' style={{ minHeight: '45vh' }}><img src={WebarFrame2} className="webarFramePic" /></div>
                        </div>
                    </div>
                    <div className='webarContainerColumn' style={{ backgroundColor: "#eed69536" }}>
                        <div  ref={tcoRef} >
                            <div className='centerDiv w_h_100' style={{ minHeight: '45vh' }}><img src={WebarFrame3} className="webarFramePic" /></div>
                        </div>
                    </div>
                </div>

                <div className="exploring" ref={contactRef} style={{ marginTop: '0' }}>
                    <div className="row container" style={{ backgroundImage: `url(${worldmap})` }}>
                        <div className="expo-col col-12 col-lg-5" style={{ padding: '10% 0% 3% 6%' }}>
                            <h4 className="expo-h4">
                                Please go ahead and schedule to bring a better change to beat your sales goals.
                            </h4>
                            <h3 className="expo-h3">
                                Ask about OneXR products & Solutions, pricing, implementation, or
                                anything else. Our representatives are standing by, ready to help.
                            </h3>
                            <div className='expoBtnDiv'> <a style={{ display: 'contents' }} target='blank' href='https://calendly.com/admin-nzq'><button className="expo-btn"> Click Here <i className="bi bi-arrow-right-short"></i></button></a></div>
                        </div>
                        <div className="expo-col2 col-12 col-lg-7" style={{ padding: '1% 0 1% 0' }}>
                            <div className='contactFormDiv' style={{ padding: '5% 0' }}>
                                <h3 className='mb-5' style={{ color: '#1495d6', fontSize: '29px', fontWeight: '700', textAlign: 'center', margin: '0 0 12px 0' }}>Contact </h3>

                                <form onSubmit={(event) => formSubmit(event)} className='oneRXContactForm'>
                                    <div className="row p-4">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <label>First Name <b>*</b></label>
                                            <input type="text" className="form-control" placeholder="First Name" onChange={(e) => setfirstName(e.target.value)} required />
                                            <p className="form-txt">Enter your first name here</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <label>Last Name <b>*</b></label>
                                            <input type="text" className="form-control" placeholder="Last Name" onChange={(e) => setlastName(e.target.value)} required />
                                            <p className="form-txt">Enter your last name here</p>
                                        </div>
                                    </div>
                                    <div className="row p-4">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <label>Email Address <b>*</b></label>
                                            <input type="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                                            <p className="form-txt">Example: user@website.com</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <label>Subject <b>*</b></label>
                                            <input type="text" className="form-control" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} required />
                                            <p className="form-txt">How can we help you?</p>
                                        </div>
                                    </div>
                                    <div className="row p-4">
                                        <div className="col-12">
                                            <label>Comments / Questions <b>*</b></label>
                                            <textarea type="text" className="form-control" placeholder="" onChange={(e) => setcommentTextArea(e.target.value)} required></textarea>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary">SUBMIT</button>
                                        <ToastContainer
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                        />
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default WebAR;
