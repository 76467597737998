import React, { useEffect, useState } from 'react';
import augraylogo from "../assets/images/homepage-images/augray-logo-2.webp";
import bannerimg from "../assets/images/company-images/banner_img.png";
import elementimg1 from "../assets/images/company-images/element-img-1.png";
import reportcheckimg from "../assets/images/company-images/report_check-img.png";
import reportcheckimg2 from "../assets/images/company-images/report_check-img-2.png";
import trophy from "../assets/images/company-images/trophy.svg";
import suresh from "../assets/images/company-images/suresh.jpg";
import ramya from "../assets/images/company-images/ramya.jpg";
import sam from "../assets/images/company-images/sam.png";
import { Helmet } from 'react-helmet';
import $ from 'jquery';


function Company() {

    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };

        window.addEventListener('scroll', handleScroll);

        $('.secondman').addClass('delay').delay(1500).queue(function () {
            $('.secondman').removeClass('delay')
        })

        $('.thirdman').addClass('delay').delay(2500).queue(function () {
            $('.thirdman').removeClass('delay')
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });



    }, []);

    return (
        <>
        <Helmet>
            <title>OneXR - Company</title>
            <meta name="description" content="Augray's vision is to provide AR / VR/ MR platform applications for smartphones, tablets and wearables that empower consumers with valuable visual experiences." />
        </Helmet>
            <div className="wrapper1">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="aboutus-main">
                    <div className="aboutus-content ">
                        <div className="aboutus-contain mt-5">
                            <img src={augraylogo} className="logoInAugray" />
                            <p>
                                Augray's vision is to provide AR / VR/ MR platform applications for smartphones, tablets
                                and wearables that empower consumers with valuable visual experiences. We augment
                                the real and virtual world with fun, emotional, educational and timely insights. Augray
                                provides a disruptive medium to consume information in a way technology has
                                never been able to do.
                            </p>
                        </div>
                    </div>
                    <div className="animated-bg">
                        <img src={bannerimg} className="mainbg " />
                        <img src={elementimg1} className="firstman" />
                        <img src={reportcheckimg} className="secondman" />
                        <img src={reportcheckimg2} className="thirdman" />
                    </div>
                </div>

                <div className="onexr-timeline">
                    <div>
                        <div className="timeline-head">
                            <h5>Augray's</h5>
                            <h3>Timeline</h3> </div>
                        <div className="timeline">
                            <div className="timeline-box left">
                                <div className="year-left">
                                    <h4>2014</h4></div>
                                <div className="content">
                                    <p>Augray built from Wisdom InfoTech, to create and provide rich and immersive experiences to speak with their clients</p>
                                    <img src={trophy} className="icon-left" />
                                </div>
                            </div>
                            <div className="timeline-box right">
                                <div className="year-right">
                                    <h4>2015</h4></div>
                                <div className="content">
                                    <p>Expanded our presence in New Delhi, Mumbai and Bangalore.</p>
                                    <img src={trophy} className="icon-right" />
                                </div>
                            </div>
                            <div className="timeline-box left">
                                <div className="year-left">
                                    <h4>2016</h4></div>
                                <div className="content">
                                    <p>Projects delivered in wide verity of verticals for the first time in India. Travel, Hospitality and Gaming.</p>
                                    <img src={trophy} className="icon-left" />
                                </div>
                            </div>
                            <div className="timeline-box right">
                                <div className="year-right">
                                    <h4>2017</h4></div>
                                <div className="content">
                                    <p>Launched worlds firstAR messaging plat- form TADA TIME</p>
                                    <img src={trophy} className="icon-right" />
                                </div>
                            </div>
                            <div className="timeline-box left">
                                <div className="year-left">
                                    <h4>2019</h4></div>
                                <div className="content">
                                    <p>India's first WEB XR solution launched along with first of its kind video XR solution.</p>
                                    <img src={trophy} className="icon-left" />
                                </div>
                            </div>
                            <div className="timeline-box right">
                                <div className="year-right">
                                    <h4>2022</h4></div>
                                <div className="content">
                                    <p>Launched ONEXR -Suite of XR Products and Solutions for Businesses</p>
                                    <img src={trophy} className="icon-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="leadership_team">
                    <div className="leadership_head">
                        <h5>Think Tank Behind 'AUGRAY'</h5>
                        <h3>LEADERSHIP TEAM</h3> </div>
                    <div className="img_row1 row">
                        <div className="col-12 col-lg-4">
                            <img src={suresh} className="face_img" />
                            <h2 className="face_name">Suresh Thankavel</h2>
                            <p className="position">CEO</p> <a target='blank' href="https://www.linkedin.com/in/sureshthankavel/"><i className="bi bi-linkedin"></i></a> </div>
                    </div>
                    <div className="img_row2 row">
                        <div className="col-12 col-lg-4">
                            <img src={ramya} className="face_img" />
                            <h2 className="face_name">Ramya S</h2>
                            <p className="position">Director - Business Operations and Research</p> <a target='blank' href="https://www.linkedin.com/in/sara-ramya/" ><i className="bi bi-linkedin"></i></a> </div>
                        <div className="col-12 col-lg-4">
                            <img src={sam} className="face_img" />
                            <h2 className="face_name">Swaminathan A</h2>
                            <p className="position">CTO</p> <a target='blank' href="https://www.linkedin.com/in/samsgates/"><i className="bi bi-linkedin"></i></a> </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Company;
