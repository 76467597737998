import React, { useEffect, useState } from 'react';
import zigzag2 from "../assets/images/contact-us-images/zigzag_2.svg";
import triangle from "../assets/images/contact-us-images/triangle.svg";
import fillcircle from "../assets/images/contact-us-images/fill_circle.svg";
import locationicon from "../assets/images/contact-us-images/location_icon.png";
import vectorman from "../assets/images/contact-us-images/vector_man.png";
import vector_man_02 from "../assets/images/contact-us-images/vector_man_02.png";
import vectorBgImg from "../assets/images/contact-us-images/bg_elements1.webp";
import contactHomeBg from "../assets/images/contact-us-images/contact_us_dot_shape.png";
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import Iframe from 'react-iframe';
import { SERVICE_API } from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../lib/Loader/Loader';
import axios from 'axios';


function ContactPage() {

	const [firstName, setfirstName] = useState('');
	const [lastName, setlastName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [commentTextArea, setcommentTextArea] = useState('');

	const [getContact, setContact] = useState([]);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true)
		axios.get("https://onexr.us:3000/api/v1/location/getAllLocation", {

		}).then((data) => {
			setContact(data.data.data)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)

		})
	}, [])

	const successToast = () => toast.success("Thanks for contact us! We will be in touch with you shortly.", {
		position: toast.POSITION.TOP_RIGHT
	});

	useEffect(() => {
		$(".navbar").css("background", "transparent");
		$(".navbar").css("box-shadow", "none")
		var btn = $('#button');
		const handleScroll = () => {
			if (window.pageYOffset > 300) {
				btn.addClass("show");
				$(".navbar").css("background", "#fff");
				$(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
			}
			else if (window.pageYOffset < 300) {
				btn.removeClass("show");
				$(".navbar").css("background", "transparent");
				$(".navbar").css("box-shadow", "none")
			}
		};
		window.addEventListener('scroll', handleScroll);
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

		btn.on('click', function (e) {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		});


	}, []);


	const formSubmit = (event) => {
		event.preventDefault();
		console.log("string");
		var formdata = new FormData();
		formdata.append("firstName", firstName);
		formdata.append("lastName", lastName);
		formdata.append("email", email);
		formdata.append("subject", subject);
		formdata.append("commentTextArea", commentTextArea);
		event.target.reset();

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(
			// "https://augray.us:3000/api/v1/email/send-demo",

			`${SERVICE_API}api/v1/email/send-demo`,
			requestOptions
		)
			.then((response) => response.text())
			.then((result) => console.log(result), successToast())
			.catch((error) => console.log("error", error));
	}

	return (
		<>
			{isLoading && <Loader />}
			<Helmet>
				<title>OneXR - Contact Us</title>
				<meta name="description" content="Chennai, India. India Headquarters 5th Floor, RR Tower II, T.V.K Industrial Estate, Guindy, Chennai - 600032, India." />
			</Helmet>
			<div className="contact fadein" style={{ backgroundImage: `url(${contactHomeBg})` }}>
				<a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
				<div className="our-ofc container">
					<img src={zigzag2} className="zigzag-img" />
					<h4 className="tit1">Get in Touch</h4>
					<img src={triangle} className="triangle-img" />
					<h2 className="tit2 text-center">Our Office Locations</h2>
					<img src={fillcircle} className="circle-img" />
					<div className="row container">
						{/* <div className="box-shadow"></div> */}
						{getContact && getContact.length > 0 && getContact.map((value, index) => (
							<div className="our-ofc1 col-lg-6 col-md-6 col-12 text-center ">

								<div className="our-ofc-location-logo d-flex justify-content-center">
									<img src={locationicon} />
								</div>
								<loc-title>{value.location}</loc-title>
								<p className="p-2">{value.quarters}</p>
								<p>{value.address}</p>
								{/* <p>USA</p> */}
								{value.phone ? <p>Phone {value.phone}</p> : "" }
								<a href={value.link}>
									<p className="p-3">Direction &#x2192;</p>
								</a>
							</div>
						))}
					</div>
				</div>

				<div className="map-loc container">
					<div className="row d-flex justify-content-center align-items-center">
						<div className="col-lg-6 col-md-6 col-12 card-model">
							<div className="map-loc1">
								<Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5833.046223100504!2d-88.144382!3d43.030424!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880507bc71d7b301%3A0x742dd42415061673!2s18650%20W%20Corporate%20Dr%20%23%20120%2C%20Brookfield%2C%20WI%2053045!5e0!3m2!1sen!2sus!4v1654577523166!5m2!1sen!2sus" width="100%" height="300" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
							</div>
							<div className="map-loc2">
								<Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7774.689921279984!2d80.210025!3d13.013691!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd53bf53a309c1442!2sAugray%20%7C%20Augmented%20Reality%20(AR)%2CVirtual%20Reality%20(VR)%20%26%20Mixed%20reality%20(MR)%20Company!5e0!3m2!1sen!2sus!4v1654577550162!5m2!1sen!2sus" width="100%" height="300" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
							</div>
						</div>
						<div className="col-lg-5 col-md-5 col-12 form-page vectorFormBg" style={{ backgroundImage: `url(${vectorBgImg})` }}>
							<div className='mb-5'>
								<h4 className="form-head2 text-end">Send a message</h4>
								<h3 className="form-head2 text-end">Let us know what you have in mind</h3>
							</div>
							<div className='contactFormDiv'>
								<form onSubmit={(event) => formSubmit(event)} className='oneRXContactForm'>
									<div className="row p-4">
										<div className="col-lg-6 col-md-6 col-12">
											<label>First Name <b>*</b></label>
											<input type="text" className="form-control" placeholder="First Name" onChange={(e) => setfirstName(e.target.value)} required />
											<p className="form-txt">Enter your first name here</p>
										</div>
										<div className="col-lg-6 col-md-6 col-12">
											<label>Last Name <b>*</b></label>
											<input type="text" className="form-control" placeholder="Last Name" onChange={(e) => setlastName(e.target.value)} required />
											<p className="form-txt">Enter your last name here</p>
										</div>
									</div>
									<div className="row p-4">
										<div className="col-lg-6 col-md-6 col-12">
											<label>Email Address <b>*</b></label>
											<input type="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
											<p className="form-txt">Example: user@website.com</p>
										</div>
										<div className="col-lg-6 col-md-6 col-12">
											<label>Subject <b>*</b></label>
											<input type="text" className="form-control" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} required />
											<p className="form-txt">How can we help you?</p>
										</div>
									</div>
									<div className="row p-4">
										<div className="col-12">
											<label>Comments / Questions <b>*</b></label>
											<textarea type="text" className="form-control" placeholder="" onChange={(e) => setcommentTextArea(e.target.value)} required></textarea>
										</div>
									</div>
									<div className="p-5 text-center">
										<button type="submit" className="btn btn-primary">SUBMIT</button>
										<ToastContainer
											autoClose={5000}
											hideProgressBar={false}
											newestOnTop={false}
											closeOnClick
										/>
									</div>
								</form>
							</div>
						</div>
					</div> <img src={vectorman} className="vector1" /> <img src={vector_man_02} className="vector2" /> </div>
			</div>

		</>
	);
}

export default ContactPage;


