import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';

import Accelerating from "../../assets/images/resource_images/Accelerating.webp";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import resourseJson from "../../Json/resources.json";
import axios from 'axios';
import Shimmer from 'react-js-loading-shimmer';



function ResourcesDetails() {
  
  const params = useLocation();
  const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);

  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true)
    axios.get("https://onexr.us:3000/api/v1/blog/getAllBlog", {
      headers: {

      }
    }).then((data) => {
      setData(data.data.data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    })


    $(".navbar").css("background", "transparent");
    $(".navbar").css("box-shadow", "none")
    var btn = $('#button');

    const handleScroll = () => {
      if (window.pageYOffset > 500) {
        btn.addClass("show");
        $(".navbar").css("background", "#fff");
        $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
      }
      else if (window.pageYOffset < 500) {
        btn.removeClass("show");
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
      }
    };
    window.addEventListener("scroll", handleScroll);

    btn.on('click', function (e) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [])
  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     axios.get("https://onexr.us:3000/api/v1/blog/getAllBlog", {
  //       headers: {
  //       }
  //     }).then((data) => {
  //       setData(data.data.data)
  //       setLoading(false)
  //     }).catch((error) => {
  //       setLoading(false)
  //     })
  //   }, 5000);

  // }, [])
  const ScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  const randomArray = shuffleArray(data)
    .filter((item, index) => item.path != window.location.pathname)


  const singleArray = data.filter(obj => {
    return obj.id === params.state.id;
  });

  const RelatedPostList = randomArray
    .filter((item, index) => index < 4)
    .map((value, index1) => {
      return (
        <div className="col-lg-3 col-md-3" key={index1}>
          <Link to={`/resources-details/${value.slug}` } state={{ id: value.id }}>
            <img src={value.image} className="related-post-img postLink" id='postLink' onClick={ScrollToTop}/>
            <h5 className="pt-3 postLink" onClick={ScrollToTop}>{value.name}</h5> </Link>
          <p className="pt-3">{value.description}</p>
        </div>
      );
    });



  
  return (
    <>
      {(isLoading ? 
      <>
      <div className="wrapper1">
          <div className="case_main_image">
            <div><Shimmer  className={"postMainImageContainerLoader"}/> </div>
          </div>

          <div className="case-head">
            <Shimmer className={"postContentHeaderLoader"}/>
            <Shimmer className={"postContentHeaderLoader"}/>
          </div>

          <div className="case-details">
            <div className='mt-4'>
              <Shimmer className={"contentSubHead"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
            </div>
            <div className='mt-4'>
              <Shimmer className={"contentSubHead"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
            </div>
            <div className='mt-5'>
              <Shimmer className={"contentSubHead"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
            </div>
            <div className='mt-5'>
              <Shimmer className={"contentSubHead"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
            </div>
            <div className='mt-5'>
              <Shimmer className={"contentSubHead"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
            </div>
            <div className='mt-5'>
              <Shimmer className={"contentSubHead"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
              <Shimmer className={"trendHeaderLoader"}/>
            </div>

          </div>

          <div>
            <h6><Shimmer className={"contentSubHead"}/></h6>
            <h2><Shimmer className={"contentSubHead"}/></h2>

            <div className="row">
              <div className="col-lg-3 col-md-3">
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5 className="text-center">
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                  <p>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </p>
              </div>
              <div className="col-lg-3 col-md-3">
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5 className="text-center">
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                  <p>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </p>
              </div>
              <div className="col-lg-3 col-md-3">
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5 className="text-center">
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                  <p>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </p>
              </div>
              <div className="col-lg-3 col-md-3">
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5 className="text-center">
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                  <p>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </>  
      :data && data.length > 0 && singleArray[0].name ? (
        <div className="wrapper1">
          <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
          <div className="case_main_image">
            <div><img src={singleArray[0]?.image} /> </div>
          </div>

          <div className="case-head">
            <div className="container">
              <h2>{singleArray[0]?.name}</h2>
            </div>
          </div>

          <div className="case-details">
            {parse(singleArray[0]?.full_description)}

          </div>

          <div className="related-post">
            <h6>On Key</h6>
            <h2>Related Post</h2>

            <div className="row">
              {data && data.length > 0 && RelatedPostList}

            </div>
          </div>
        </div>
      ) : (
        <div className="wrapper1">
          <div className="related-post">
            <h6>On Key</h6>
            <h2>Related Post</h2>

            <div className="row">
              {data && data.length > 0 && RelatedPostList}
            </div>
          </div>
        </div>
      )
      )}

    </>
  );
}

export default ResourcesDetails;

