import React, { useEffect, useState } from 'react';

import print_ar_main from "../../assets/images/printar/print_ar_main.png";
import Group263 from "../../assets/images/homepage-images/Group263.svg";
import Group264 from "../../assets/images/homepage-images/Group264.svg";
import Group265 from "../../assets/images/homepage-images/Group265.svg";
import Group266 from "../../assets/images/homepage-images/Group266.svg";
import product_1 from "../../assets/images/printar/product-1.png";
import product_2 from "../../assets/images/printar/product-2.png";
import product_3 from "../../assets/images/printar/product-3.png";
import solution_1 from "../../assets/images/printar/solution-1.png";
import solution_2 from "../../assets/images/printar/solution-2.png";
import solution_3 from "../../assets/images/printar/solution-3.png";
import solution_4 from "../../assets/images/printar/solution-4.png";
import tractor from "../../assets/images/printar/tractor.png";

import print_ar_bg from "../../assets/images/printar/print_ar_bg.jpg";
import dots from "../../assets/images/ar-vr-images/dots.png";
import ar_vr_bg from "../../assets/images/ar-vr-images/ar-vr_bg.jpeg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



function PrintARPage() {

    var ProductSettings = {
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed:1000,
        speed: 8000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);
    return (
        <>

        <Helmet>
            <title>OneXR - Print AR</title>
            <meta name="description" content="PrintAR makes the whole print content more personalized and accessible than ever. With customizable AR content, you can target and attract all demographics." />
        </Helmet>
            <div className="wrapper1">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="print_ar_main" style={{ backgroundImage: `url(${print_ar_bg})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-4 p-5">
                            <div className=" mt-5">
                                <h1>OneXR</h1>
                                <h2>Print AR</h2>
                                <p><b>“It's all about the experience!”
                                    Do you know you need a way to make your print marketing stand out from the crowd and get noticed?!</b> Print AR allows your marketing content to go beyond print & promotional giveaways into the digital world and transform it into a lead generation medium. </p>
                            </div>
                        </div>
                        <div className=" col-12 col-lg-6 "> <img src={print_ar_main} className="" /> </div>
                    </div>
                </div>

                <div className="benefits row m-0 pt-2 pb-2">
                    <div className="col-lg-3 col-md-3">
                        <h1>Beneficiaries of <br />OneXR</h1> </div>
                    <div className=" col-lg-9 col-md-9">
                        <div className="carousel grab">
                            <Slider {...ProductSettings}>
                                <div> <img src={Group263} />
                                    <p className="icon-para">Automotive</p>
                                </div>
                                <div> <img src={Group264} />
                                    <p className="icon-para">Construction</p>
                                </div>
                                <div> <img src={Group265} />
                                    <p className="icon-para">Maintanance & Repair</p>
                                </div>
                                <div> <img src={Group266} />
                                    <p className="icon-para">Manufatoring</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="ar-vr-products">
                    <div className="content-row1">
                        <div className="printContent1">
                            <h2>How can <strong>Print Augmentation</strong> can bring
                                your static print and giveaways to life?
                            </h2> </div>
                    </div>
                    <div className="card-row row container">
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_1} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>"Take your marketing efforts one step further!"</h1>
                                    <p><b>Get more brand reach</b> through OneXR! WebAR helps your print brochure and other forms to <b>come to life, making it more appealing to consumers.</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_2} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Attracting all demographics with print content</h1>
                                    <p>PrintAR makes the whole print content more <b>personalized and accessible</b> than ever. With <b>customizable AR content,</b> you can <b>target and attract all demographics.</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_3} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>High user engagement</h1>
                                    <p>Printing has booming dimensions, subtle printing won't take you anywhere! Providing <b>immersive content</b> to the user, benefits businesses to <b>thrive and reach unprecedented altitudes!</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="ar-vr-solution">
                    <div className="content-row2">
                        <div className="printContent1">
                            <h2>Offer customers a new way to experience your brand with print AR.
                                Get the AR content you need to be delivered to your prospects doorstep</h2> </div>
                    </div>
                    <div className="solution-row row container pt-4 pb-4">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div>
                                <img src={solution_1} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                            </div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Make your customer experience immersive content without downloading the app</h2>
                                <p className="pro-p" >Businesses get stuck in <b>offering easy to use and friendly content</b> to the users. But customers soon <b>lose interest</b> as they don't have exciting, <b>innovative elements to keep them engaged.</b>
                             
                              Studies say <b>80% retention</b> is brought when adding <b>Web-Based interactive content</b> in businesses! Using <b>AR/VR interactive, lively content in packages, pamphlets,
                                    and other promotional giveaways</b> will <b>increase brand awareness and easy access to
                                        knowledge about your business and products!</b></p> </div>
                        </div>
                    </div>
                    <div className="solution-row row container pt-4 pb-4">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Easy interaction, live results achieved</h2>
                                <p className="pro-p"><b>Acquiring people's interest and attention</b> seem to be the toughest thing for businesses. The customers expect an <b>easy approach and a ready to serve information</b> about the product and brand instantly.
                                 
                                  Be there when your customers are <b>making purchase decision.</b> Interact with your customers instantly with the <b>easily accessible scannable QR codes.</b> PrintAR helps businesses achieve quick results by providing <b>interactive & immersive
                                        content</b> related to products or brand, gaining instant decisions from the prospects. Let your customers gel with your products on the go. Enable your customers to <b>experience
                                            the essence of comfy technology from their homes!</b> </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div>
                                <img src={solution_2} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                            </div>
                        </div>
                    </div>
                    <div className="solution-row row container pt-4 pb-4">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div>
                                <img src={solution_3} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                            </div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Manage content and track results of your campaign </h2>
                                <p className="pro-p">We see businesses struggle to provide new <b>engaging content</b> every now and then, to their customers and at the same time make their <b>marketing activities data driven.</b>
                                 
                                  The CMS is designed for you to enable the QR code with new immersive content for your customers, making your <b>print live and fresh,</b> it also helps you and your businesses to maintain the records accurately to achieve the target, fulfilling the needs of the audience at the same. CMS, <b>keep track of their views, insights,
                                        analytics, and other resources with emotional connection and brand engagement!</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="solution-row row container pt-4 pb-4">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">A simple and hassle-free method</h2>
                                <p className="pro-p">Businesses search for various methods to market the products but fail to <b>create emotional connectivity</b> to their target audience. The secret to <b>profitable marketing</b> is to let the customers know the history and story of the product, and the business, which pushes the audience to connect them with the products as the story seems highly relatable. Let your customer experience your products with immersive visuals in 3D, just by scanning QR codes from their device. This helps the users to scan and involve in the journey of your product and business! </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div>
                                <img src={solution_4} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-bg">
                    <div className="contact-expo">
                        <div className="row p-5" style={{ backgroundImage: `url(${ar_vr_bg})` }}>
                            <div className="col-12 col-lg-5 "> <img src={tractor} className="printArExpoImg" /> </div>
                            <div className="col-12 col-lg-3 ">
                                <p style={{fontWeight:"500", fontSize:"17px"}}>AR Print is redefining how we engage with the print industry.
                                    We enable our clients to create digital content while delivering Physical print content.</p>
                                <p style={{fontWeight:"500", fontSize:"17px"}}>Be part of Endless Possibilities! From 3D model catalogs to product
                                    visualization, our platform empowers brands to create amazing content and
                                    engage with their target audiences in a completely interactive way. </p> 
                                    <div className="expoBtnDiv"><Link to="/contact"><i className="bi bi-check2"></i> Contact Us</Link> </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default PrintARPage;
