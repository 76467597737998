import React, { useEffect, useState } from 'react';

import ar_vr_mainimg from "../assets/images/swag/swagbg1.png";

import slideImg1 from "../assets/images/swag/slideImg1.png";
import slideImg2 from "../assets/images/swag/slideImg2.png";
import slideImg3 from "../assets/images/swag/coster.png";

import Frame1 from "../assets/images/swag/Frame1.png";
import Frame2 from "../assets/images/swag/Frame2.png";
import Frame3 from "../assets/images/swag/Frame3.png";
import chartImg from "../assets/images/swag/graph.png";

import dots from "../assets/images/ar-vr-images/dots.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { SERVICE_API } from '../config';
import 'react-toastify/dist/ReactToastify.css';


function ARVRProduct() {

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [commentTextArea, setcommentTextArea] = useState('');

    const [getContact, setContact] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        axios.get("https://onexr.us:3000/api/v1/location/getAllLocation", {

        }).then((data) => {
            setContact(data.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)

        })
    }, [])

    const successToast = () => toast.success("Thanks for contact us! We will be in touch with you shortly.", {
        position: toast.POSITION.TOP_RIGHT
    });


    const formSubmit = (event) => {
        event.preventDefault();
        console.log("string");
        var formdata = new FormData();
        formdata.append("firstName", firstName);
        formdata.append("lastName", lastName);
        formdata.append("email", email);
        formdata.append("subject", subject);
        formdata.append("commentTextArea", commentTextArea);
        event.target.reset();

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(
            // "https://augray.us:3000/api/v1/email/send-demo",

            `${SERVICE_API}api/v1/email/send-demo`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => console.log(result), successToast())
            .catch((error) => console.log("error", error));
    }

    // -------------- Text animation ---------
    useEffect(() => {
        consoleText([' Awe’s.', 'Talks.', 'Recalls.', 'Demonstrates.', 'Generate Leads'], 'text', ['#00BBE1', '#00EB50', '#fff000', '#ff3399', '#fff']);

        function consoleText(words, id, colors) {
            if (colors === undefined) colors = ['#fff'];
            var visible = true;
            var con = document.getElementById('console');
            var letterCount = 1;
            var x = 1;
            var waiting = false;
            var target = document.getElementById(id)
            target.setAttribute('style', 'color:' + colors[0])
            window.setInterval(function () {

                if (letterCount === 0 && waiting === false) {
                    waiting = true;
                    target.innerHTML = words[0].substring(0, letterCount)
                    window.setTimeout(function () {
                        var usedColor = colors.shift();
                        colors.push(usedColor);
                        var usedWord = words.shift();
                        words.push(usedWord);
                        x = 1;
                        target.setAttribute('style', 'color:' + colors[0])
                        letterCount += x;
                        waiting = false;
                    }, 1000)
                } else if (letterCount === words[0].length + 1 && waiting === false) {
                    waiting = true;
                    window.setTimeout(function () {
                        x = -1;
                        letterCount += x;
                        waiting = false;
                    }, 1000)
                } else if (waiting === false) {
                    target.innerHTML = words[0].substring(0, letterCount)
                    letterCount += x;
                }
            }, 120)
            window.setInterval(function () {
                if (visible === true) {
                    con.className = 'console-underscore hidden'
                    visible = false;

                } else {
                    con.className = 'console-underscore'

                    visible = true;
                }
            }, 400)
        }
    }, [])

    // ------- Navigation --------
    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);

    return (
        <>
            <div className="warpper1">
                <a id="button"><i className="fa-solid fa-angle-up"></i></a>
                <div className="ar-vr-main centerDiv" style={{ backgroundImage: `url(${ar_vr_mainimg})`, opacity: '1', height: '60vh' }}>
                    <div className='centerDiv w-100'>
                        <h1 style={{ color: '#fff', padding:'.5% 4% 1%', borderRadius:'55px', backgroundColor:'#000000bf' }} class='centerDiv console-container'><span style={{ paddingLeft: '10px' }} id='text'></span><div class='console-underscore' id='console'>&#95;</div></h1>
                    </div>
                </div>

                <div className="ar-vr-solution">
                    <div className="solution-row row  container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={Frame1} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 style={{ color: '#1495d6' }}>Sample collection of an Expo visitor</h2>
                                <h5 >I hope you can relate to this</h5>
                                <p className="pro-p">Do you recall any of the swags you have with you now? If so what are they? and how many?</p>
                                <p className="pro-p">Study shows 23 percent of corporate swags are thrown away,<br></br>
                                    50 per cent were given away.</p>
                                <p className="pro-p">Either they are Cheap, Generic, or nothing special.</p>
                                <p className="pro-p">Only a few are recollected and works.</p>
                            </div>
                        </div>

                    </div>


                    <div style={{ backgroundColor: "#fcf0d89e", width: '100%', padding: '2% 0' }}>
                        <div className="solution-row row container" style={{ margin: '2% 0' }}>
                            <div className="text-column col-12 col-lg-6">
                                <div className="card">
                                    <h6>OneXR</h6></div>
                                <div>
                                    <h2 style={{ color: '#1495d6' }}>Research says swags do work, provided they are</h2>
                                    <p className="pro-p">
                                        1) Custom-made that comes with a cost<br></br>
                                        2) Unique that is not common <br></br>
                                        3) Make it useful and sticky</p>

                                </div>
                            </div>
                            <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                                <div><img src={Frame2} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                            </div>
                        </div>
                    </div>

                    <div className="solution-row row  container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={Frame3} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2"> </h2>
                                <h2 style={{ color: '#1495d6' }}>Let your Swags work with a cost-effective solution</h2>
                                <p className="pro-p">Go on and scan this smiley.</p>
                                <p className="pro-p">Hope you did and experienced it, if not scan using your phone and give permission to your camera to see it on your desk in AR.</p>
                                <p className="pro-p">
                                    I bet it was an Awe! and you would recall the experience. <br />
                                    Let your swags stand out with a simple, cost-effective solution.<br />
                                    Wondering how come I did not know this!</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: "#d4bcec36", width: '100%', padding: '2% 0' }}>
                    <div className="ar-vr-products" style={{ margin: '0' }}>
                        <div className="content-row1">
                            <div className="content1 mb-4">
                                <h3>Innovative</h3>
                                <h2>Innovative, cost-effective giveaways that not only work but thrive!</h2>
                            </div>
                        </div>
                        <div className="card-row row container ar_vr_solutionCol">
                            <div className="col-12 col-lg-4 col-md-4">
                                <div className="card-container card-border p-3">
                                    <div className='title'> <img src={slideImg1} className="pro-img" /> </div>
                                    <div className="card-content">
                                        <h1>Engine - your product</h1>
                                        <p>A <b>cost-effective</b> and <b>attractive stress ball,</b> just like that of <b>your product, the shape   </b> of an engine with <b>your brand</b> and QR code that provides an immersive <b>AR experience</b> for your audience to see your <b>product come alive</b>, in AR.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 col-md-4">
                                <div className="card-container card-border p-3">
                                    <div className='title'> <img src={slideImg2} className="pro-img" /> </div>
                                    <div className="card-content">
                                        <h1>Loader -  Samples</h1>
                                        <p>We produce stress balls in the shape of your product—a physical recall factor. </p>
                                        <p><br /></p>
                                        <p><br /></p>
                                        <p><br /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 col-md-4">
                                <div className="card-container card-border p-3">
                                    <div className='title'> <img src={slideImg3} className="pro-img" /> </div>
                                    <div className="card-content">
                                        <h1>Coster and more</h1>
                                        <p>Provide something useful, sticky and demonstrate your product in AR that can change.

                                        We have a lot more choices to choose from.</p>
                                        <p><br /></p>
                                        <p><br /></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="benefits row  pt-3 pb-3">
                    <div className="col-lg-8 col-md-8">
                        <h1>Schedule now to get your personalized that works in </h1>

                    </div>
                    <div className="col-lg-4 col-md-4 centerDiv">
                        <a style={{ display: 'contents' }} target='blank' href='https://calendly.com/onexr/30min'><button class="pro-btn">Calendly</button></a>
                    </div>

                </div>

                <div style={{ backgroundColor: "#fcf0d89e", width: '100%' }}>
                    <div className="ar-vr-solution" style={{ margin: '0' }}>
                        <div className="solution-row row container" style={{ margin: '0' }}>
                            <div className="text-column col-12 col-md-6 col-lg-6">
                                <div className="card">
                                    <h6>OneXR</h6>
                                </div>
                                <div>
                                    <div>
                                        <h4>Wonder how long does it take to get yours?</h4>
                                        <p className="pro-p">In just a few weeks. We will work on your content and make it AR (Augmented Reality) ready, personalize the shape and color of your choice.</p>
                                        <h4>How are the contents maintained?</h4>
                                        <p className="pro-p">We will maintain your content in the cloud (state-of-the-art secured AWS servers.) Continue upgrading and supporting all appropriate phones and tablets as and when new versions of phones, tablets, and software are released.</p>
                                        <h4>What if I need to change the content?</h4>
                                        <p className="pro-p">Contact us on any change to your content (new products, contact information, etc.). We will be happy to update your content.</p>
                                        <h4>What is the minimum order?</h4>
                                        <p className="pro-p">The minimum is a quantity of 100.</p>
                                        <h4>What countries are supported?</h4>
                                        <p className="pro-p">We deliver it to all countries where it can be shipped.</p>
                                        <p className="pro-p" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>Contact us on :  <div className="col-lg-6 col-md-5 ms-2">
                                            <a style={{ display: 'contents' }} target='blank' href='https://calendly.com/onexr/30min'><button class="pro-btn" style={{ padding: '2.5% 11%' }}>Calendly</button></a>
                                        </div>    </p>

                                    </div>
                                </div>
                            </div>
                            <div className="img-column col-12 col-md-6 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                                <div><img src={chartImg} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default ARVRProduct;
