
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import brandlogo from "../../assets/images/homepage-images/brandlogo.webp";
import augraylogo from "../../assets/images/homepage-images/augray-logo.png";

function Footer() {
    // useEffect(() => {

    // }, []);

    return (
        <div className="footer container mb-5">
            <div className="row">
                <div className="col-lg-3 col-md-3 col-12">
                    <Link to="/ar-vr">AR / VR APP</Link>
                    <Link to="/web-virtual-showroom">Web Virtual Showroom</Link>
                    <Link to="/kiosk">Kiosk / LMS Application</Link>
                    <Link to="/print-ar">Print AR</Link>
                    <Link to="/lens">Lens</Link>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <Link to="/company">Company</Link>
                    <Link to="/resources">Resources</Link>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/requestDemo">Request For Demo</Link>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <a href="https://augray.com/privacy-policy">Privacy Policy</a> 
                    <a href="https://augray.com/terms-&-condition">Terms & Conditions</a>
                </div>
            </div>
            <div className="footer-img">
                <Link to="/">
                    <div className="footer-brand">
                        <img src={brandlogo} />
                    </div>
                </Link>
                <div className="footer-p">
                    <h4>Product of</h4>
                </div>
                <a href="https://augray.com/">
                    <div className="footer-clogo">
                        <img src={augraylogo} />
                    </div>
                </a>
                <div className="footer-logos">
                    <a href="https://www.linkedin.com/company/onexrsolutions/" className="logo1"><i className="bi bi-linkedin"></i></a>
                    <a href="https://www.youtube.com/channel/UC4jEPPEwWPdKO8C-FO3EW_g" className="logo2"><i className="bi bi-youtube"></i></a>
                    <a href="https://www.facebook.com/onexrsolutions/" className="logo3"><i className="bi bi-facebook"></i></a>
                    <a href="https://www.instagram.com/onexr.solutions/?hl=en" className="logo4"><i className="bi bi-instagram"></i></a>
                </div>
                <div className="copyright">
                    <h4>&#169;2022, <b>OneXR</b>. All Rights Reserved.</h4>
                </div>
            </div>
        </div>
    );
}

export default Footer;
