import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import bredcumbs_1 from "../../../assets/images/resource_images/bredcumbs-1.png";
import resourseJson from "../../../Json/resources.json";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import Shimmer from 'react-js-loading-shimmer';


function BlogCategory() {
  const params = useLocation();

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios.get("https://onexr.us:3000/api/v1/blog/getAllBlog", {
      headers:{

      }
    }).then((data) => {
      setData(data.data.data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    })


    $(".navbar").css("background", "#fff");
    $(".navbar").css("box-shadow", "0px 15px 10px -15px #111");
    var btn = $("#button");
    const handleScroll = () => {
      if (window.pageYOffset > 500) {
        btn.addClass("show");
        $(".navbar").css("background", "#fff");
        $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
      } else if (window.pageYOffset < 500) {
        btn.removeClass("show");
        $(".navbar").css("background", "#fff");
        $(".navbar").css("box-shadow", "0px 15px 10px -15px #111");
      }
    };
    window.addEventListener("scroll", handleScroll);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    btn.on('click', function (e) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [])
  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     axios.get("https://onexr.us:3000/api/v1/blog/getAllBlog", {
  //       headers: {
  //       }
  //     }).then((data) => {
  //       setData(data.data.data)
  //       setLoading(false)
  //     }).catch((error) => {
  //       setLoading(false)
  //     })
  //   }, 5000);

  // }, [])
  const ScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }


  const BlogPostList = data.map((data1) => {
    return data1.tag.map((tag) => {
      if (tag.tagname == params.state.tagname) {
        return (
          <div className="container pt-5 pb-5 d-flex justify-content-center">
            <div className="case1-year-card">
              <img src={data1.image} className="case1-year-card-img-top" />
              <div className="case1-year-card-body p-5">
                <p className="p-3">
                  {data1.tag.map((tag) => {
                    return (
                      <Link to="/blog" state={{ tagname: tag.tagname }} onClick={ScrollToTop}>
                        <i className="bi bi-folder-fill"></i> {tag.tagname}
                      </Link>
                    );
                  })}
                </p>
                <h5 className="case1-year-card-title p-3">
                  <Link to={`/resources-details/${data1.slug}`} state={{ id: data1.id }} onClick={ScrollToTop}> {data1.name}</Link>
                </h5>
                <p className="case1-year-card-text p-3"> {data1.description}</p>
                <Link to={`/resources-details/${data1.slug}`} state={{ id: data1.id }} onClick={ScrollToTop}>
                  <button className="case1-year-card-btn">
                    Continue <i className="bi bi-arrow-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        );
      }
    });
  });


  
  return (
    <>
      <div className="wrapper1">
        <a id="button">
          <i className="bi bi-arrow-up-square-fill"></i>
        </a>
        <div className="case1-year">
          <div
            className="case1-year-bg text-center"
            style={{ backgroundImage: `url(${bredcumbs_1})` }}
          >
            <h1 style={{ textTransform: 'capitalize' }}>Category: {params.state.tagname}</h1>{" "}
          </div>
        </div>
        {(isLoading ?
          <>
            <div className="container pt-5 pb-5 d-flex justify-content-center">
              <div className="case1-year-card">
                <Shimmer className={"blogPostImageLoader"} />
                <div className="case1-year-card-body p-5">
                  <p className="p-3">
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"} />
                      <Shimmer className={"trendHeaderLoader"} />
                      <Shimmer className={"trendHeaderLoader"} />
                    </div>
                  </p>
                  <h5 className="case1-year-card-title p-3">
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                  </h5>
                  <p className="case1-year-card-text p-3">
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                  </p>
                  <p style={{ width: "20%" }}>
                    <Shimmer className={"trendHeaderLoader"} />
                  </p>
                </div>
              </div>
            </div>

            <div className="container pt-5 pb-5 d-flex justify-content-center">
              <div className="case1-year-card">
                <Shimmer className={"blogPostImageLoader"} />
                <div className="case1-year-card-body p-5">
                  <p className="p-3">
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"} />
                      <Shimmer className={"trendHeaderLoader"} />
                      <Shimmer className={"trendHeaderLoader"} />
                    </div>
                  </p>
                  <h5 className="case1-year-card-title p-3">
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                  </h5>
                  <p className="case1-year-card-text p-3">
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                    <Shimmer className={"trendHeaderLoader"} />
                  </p>
                  <p style={{ width: "20%" }}>
                    <Shimmer className={"trendHeaderLoader"} />
                  </p>
                </div>
              </div>
            </div>
          </>


          : data && data.length > 0 && BlogPostList)}
      </div>
    </>
  );
}

export default BlogCategory;
