import React, { useEffect, useState } from 'react';

import lens_main from "../../assets/images/lens-images/ONE-XR-LENS-BANNER-01.png";
import SECTION_1 from "../../assets/images/lens-images/SECTION-1-IMAGE-2-01.png";
import SECTION_1_01 from "../../assets/images/lens-images/section-1-01.png";
import SECTION_1_02 from "../../assets/images/lens-images/section-1-02-1.png";
import SECTION_1_IMAGE1 from "../../assets/images/lens-images/SECTION-1-IMAGE-1-01.png";
import SECTION_1_IMAGE2 from "../../assets/images/lens-images/SECTION-1-IMAGE-2-01.png";
import lens_images1 from "../../assets/images/lens-images/section-1-01.png";
import lens_images2 from "../../assets/images/lens-images/section-1-02-1.png";
import lens_images3 from "../../assets/images/lens-images/SECTION-1-IMAGE-1-01.png";
import Remote_Camera from "../../assets/images/lens-images/Remote-Camera.png";
import Sign_1 from "../../assets/images/lens-images/Sign-1.png";
import file_transfer from "../../assets/images/lens-images/file-transfer.png";
import Image_Freezer_1 from "../../assets/images/lens-images/Image-Freezer-1.png";
import Annotation_1 from "../../assets/images/lens-images/Annotation-1.png";
import Record_a_Session from "../../assets/images/lens-images/Record-a-Session.png";
import product1 from "../../assets/images/lens-images/product-1.png";
import product2 from "../../assets/images/lens-images/product-2.png";
import product3 from "../../assets/images/lens-images/product-3.png";
import laptops from "../../assets/images/lens-images/laptops.png";

import business_02 from "../../assets/images/lens-images/business-02.png";
import business_01 from "../../assets/images/lens-images/business-01.png";
import business_03 from "../../assets/images/lens-images/business-03.png";

import lens_bg from "../../assets/images/lens-images/banner-bg.jpeg";
import dots from "../../assets/images/ar-vr-images/dots.png";
import banner_bg from "../../assets/images/lens-images/banner-bg.jpeg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



function LensPage() {

    var ProductSettings = {
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    useEffect(() => {
        $(".navbar").css("background", "#fff");
        $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
        $('.button-ch').on('click', function () {
            $(this).parent().find('.button-ch').removeClass('active-button');
            var show = $(this).data('show');
            $(show).removeClass("hide").siblings().addClass("hide");
            $(this).addClass('active-button');
        });
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                // $(".navbar").css("background", "#fff");
                // $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);

    return (
        <>
        <Helmet>
            <title>OneXR - Lens</title>
            <meta name="description" content="Empower your field technicians with the power of assisting clients through XR technology! Enable the frontline workforce to solve clients' complaints in ..." />
        </Helmet>
            <div className="wrapper1">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="lens_main" style={{ backgroundImage: `url(${lens_bg})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-5 p-5 ">
                            <div className=" mt-5">
                                <h1>OneXR</h1>
                                <h2>LENS</h2>
                                <p><b>Empower your field technicians with the power of assisting clients through
                                    XR technology!</b> Enable the frontline workforce to solve clients' complaints in real-time, enhance customer experience and transform after-sales services with OneXR lens. </p>
                            </div>
                        </div>
                        <div className=" col-12 col-lg-5 "> <img src={lens_main} className="" /> </div>
                    </div>
                </div>

                <div className="goals">
                    <div className="container printContent1"> <h2>This is how <b>OneXR lens</b> will help you to achieve your business goals! </h2></div>
                    <div className="button-row">
                        <button className="button-ch  active-button" data-show=".content-1">Live instructions at your fingertips from virtual technicians</button>
                        <button className="button-ch" data-show=".content-2"> Create and manage your dashboard!</button>
                        <button className="button-ch" data-show=".content-3"> Increased Customer Satisfaction</button>
                    </div>
                    <div className="details-row1 row">
                        <div className="content-1 ">
                            <div className="text-column col-12 col-lg-4">
                                <div className="card">
                                    <h6>OneXR</h6></div>
                                <div>
                                    <h2 className="pro-h2">Live instructions at your fingertips from virtual technicians
                                    </h2>
                                    <p className="pro-p">Most field technicians would agree that <b>watching a repair</b> being performed is preferable to reading about it. OneXR Lens allows field technicians to consult with an expert while <b>live-streaming</b> video of technical concerns. Our AR technology allows the expert to <b>contribute
                                        and resolve the problem in less time.</b></p>
                                </div>
                            </div>
                            <div className="img-column col-12 col-lg-5">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={SECTION_1} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                                </div>
                            </div>
                        </div>
                        <div className="content-2 hide">
                            <div className="text-column col-12 col-lg-4">
                                <div className="card">
                                    <h6>OneXR</h6></div>
                                <div>
                                    <h2 className="pro-h2">Create and manage your dashboard!
                                    </h2>
                                    <p className="pro-p">There are many activities to keep track of in a large organisation. <b>Session reports</b> in OneXR Lens provide a <b>thorough view of all activities</b> carried out by technicians to the Partner Admin. <b>Start time, duration,
                                        session video, session title, customer details, tickets details</b> are among the session metrics offered in OneXR Lens. You can choose from a variety of custom views to assess how your <b>after-sales services</b> is functioning in addition to these data.</p>
                                    <img src={SECTION_1_01} className="small_img" /> </div>
                            </div>
                            <div className="img-column col-12 col-lg-5">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={SECTION_1_02} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                                </div>
                            </div>
                        </div>
                        <div className="content-3 hide">
                            <div className="text-column col-12 col-lg-4">
                                <div className="card">
                                    <h6>OneXR</h6></div>
                                <div>
                                    <h2 className="pro-h2">Increases Customer Satisfaction
                                    </h2>
                                    <p className="pro-p"><b>Customer retention</b> might be problematic if there are delays in responding to customers or delivering answers. OneXR Lens enables your technicians to tackle customer concerns <b>faster</b> by allowing them to
                                        <b>start remote help sessions right away.</b> Customers will no longer have to wait for long after raising the ticket to the customer care, thanks to <b>on-demand access to product experts.</b> Customer support teams can <b>address issues more quickly</b> because they <b>don't have to travel and have immediate access to clients.</b></p>
                                </div>
                            </div>
                            <div className="img-column col-12 col-lg-5">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={SECTION_1_IMAGE1} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details-row-2">
                        <div className="justify-content-center align-items-center p-5">
                            <div id="carouselExampleSlidesOnly" className="carousel slide " data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <Slider {...ProductSettings}>
                                        <div className="carousel-item active">
                                            <div className="product-1 fadeIN">
                                                <div className="card">
                                                    <h6>OneXR</h6></div>
                                                <div>
                                                    <h2 className="pro-h2">Live instructions at your fingertips from virtual technicians</h2>
                                                    <p className="">Most field technicians would agree that <b>watching a repair</b> being performed is preferable to reading about it. OneXR Lens allows field technicians to consult with an expert while <b>live-streaming</b> video of technical concerns. Our AR technology allows the expert to <b>contribute
                                                        and resolve the problem in less time.</b></p>
                                                    <div>
                                                        <img src={SECTION_1_IMAGE2} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className=" product-2  fadeIN">
                                                <div className="card">
                                                    <h6>OneXR</h6></div>
                                                <div>
                                                    <h2 className="pro-h2">Create and manage your dashboard!</h2>
                                                    <p className="">There are many activities to keep track of in a large organisation. <b>Session reports</b> in OneXR Lens provide a <b>thorough view of all activities</b> carried out by technicians to the Partner Admin. <b>Start time, duration,
                                                        session video, session title, customer details, tickets details</b> are among the session metrics offered in OneXR Lens. You can choose from a variety of custom views to assess how your <b>after-sales services</b> is functioning in addition to these data.</p>
                                                    <img src={lens_images1} className="small_img" />
                                                    <div>
                                                        <img src={lens_images2} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="product-3  fadeIN">
                                                <div className="card">
                                                    <h6>OneXR</h6></div>
                                                <div>
                                                    <h2 className="pro-h2">Increases Customer Satisfaction </h2>
                                                    <p className=""><b>Customer retention</b> might be problematic if there are delays in responding to customers or delivering answers. OneXR Lens enables your technicians to tackle customer concerns <b>faster</b> by allowing them to <b>start remote help sessions right away.</b> Customers will no longer have to wait for long after raising the ticket to the customer care, thanks to <b>on-demand access to product experts.</b> Customer support teams can <b>address issues more quickly</b> because they <b>don't have to travel and have immediate access to clients.</b></p>
                                                    <div>
                                                        <img src={lens_images3} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="remote_assistance">
                    <div className="rem-acc-row row">
                        <div className="col-12 col-lg-4 mt-5">
                            <div className='lensAssistance'>
                                <h2>Eminent features of our</h2>
                                    <span><h2>AR remote assistance</h2></span> 
                                    <h2>software helps you detect, and fix the issues
                                    virtually in seconds!</h2>
                                <div className="dotted_hr">
                                    <hr className="hr1" />
                                    <hr className="hr1" />
                                    <hr className="hr2" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="rem_acc">
                                <div> <img src={Remote_Camera} /></div>
                                <div>
                                    <h3>Remote camera sharing</h3>
                                    <p>Provide eminent solutions right from the spots just by sharing the cameras! </p>
                                </div>
                            </div>
                            <div className="rem_acc">
                                <div><img src={Sign_1} /></div>
                                <div>
                                    <h3>Single Sign-On</h3>
                                    <p>Enable users to assist and log in with a single click without any third-party intrusions for secure access, credentials, and data collection with the high-tech CMS.</p>
                                </div>
                            </div>
                            <div className="rem_acc">
                                <div><img src={file_transfer} /></div>
                                <div>
                                    <h3>File Transfer</h3>
                                    <p>Enable users to share the files, prototype, or any other files related to work just in seconds in the AR session hassle-free! </p>
                                </div>
                            </div>
                            <div className="rem_acc">
                                <div><img src={Image_Freezer_1} /></div>
                                <div>
                                    <h3>Freeze Image</h3>
                                    <p>Get clear images of the live work and spotting out the details and highlight the errors to work on and fix! </p>
                                </div>
                            </div>
                            <div className="rem_acc">
                                <div> <img src={Annotation_1} /></div>
                                <div>
                                    <h3>3D Annotation</h3>
                                    <p>AR annotations allow you and your consumer to contribute more virtual data to the live camera feed. You can draw and write on the screen with different annotation tools. </p>
                                </div>
                            </div>
                            <div className="rem_acc">
                                <div> <img src={Record_a_Session} /></div>
                                <div>
                                    <h3>Record a Session</h3>
                                    <p>Meetings can be effectively recorded and utilized for different use-cases going from quality affirmation to the creation of more practical preparing programs that use genuine models rather than mockups and outlines. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ar-vr-products">
                    <div className="card-row row container">
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product1} className="pro-img1" /> </div>
                                <div className="card-content">
                                    <h1>Highest Safety Standards</h1>
                                    <p>With <b>advanced encryption and safety standards,</b> the camera is accessed in a controlled environment. When recording, the contents are maintained safely in <b>secured cloud servers</b> (yours if needed). You can rest assured that information is protected with our <b>best-in-class
                                        security standards.</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product2} className="pro-img1" /> </div>
                                <div className="card-content">
                                    <h1>Increased Productivity</h1>
                                    <p>When <b>issues are resolved quickly,</b> you may <b>minimise
                                        your machine downtime</b> to a bare minimum. You can resolve issues right away using OneXR Lens' comprehensive features, <b>increasing productivity and efficiency.</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product3} className="pro-img1" /> </div>
                                <div className="card-content">
                                    <h1>AR-Based Service Models</h1>
                                    <p>Why would your technician travel to a client's location if you <b>can assist them to resolve issues
                                        from the comfort of your own office?</b> Offers AR-supported service models that provide a <b>more interactive experience
                                            for customers</b> who want to experience new service concepts or <b>perform swift repairs!</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pie-slider">
                    <div className=" d-flex justify-content-center align-items-center p-5">
                        <div id="carouselExampleSlidesOnly" className="carousel slide " data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <Slider {...ProductSettings}>
                                    <div className="carousel-item">
                                        <div className="product-1 row ">
                                            <div className="col-12 col-lg-3 ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="70, 100" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">70%</text>
                                                    </svg>
                                                    <p className="span-p">Higher First-time fix rate by</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="33, 100" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">33%</text>
                                                    </svg>
                                                    <p className="span-p">Reduced Complaint escalation rate by</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="12, 100" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">12%</text>
                                                    </svg>
                                                    <p className="span-p">Reduced average ticket handling time by</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="80, 100" d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">80%</text>
                                                    </svg>
                                                    <p className="span-p">Reduced travel cost by</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-2 row ">
                                            <div className="col-12 col-lg-3  ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="89, 100" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">89%</text>
                                                    </svg>
                                                    <p className="span-p">Remote resolution rate by</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3  ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="60, 100" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">60%</text>
                                                    </svg>
                                                    <p className="span-p">Faster support response</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3  ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="20, 100" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">20%</text>
                                                    </svg>
                                                    <p className="span-p">Improvement in Phone fix rate</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3  ">
                                                <div className="single-chart">
                                                    <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                        <path className="circle-bg" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle" strokeDasharray="30, 100" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <text x="18" y="20.35" className="percentage">30%</text>
                                                    </svg>
                                                    <p className="span-p">Increase in the end customer satisfaction</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="onexr-meet">
                    <div className="container printContent1"> <h2>This is how <b>OneXR meet</b> will help you to achieveyour business goals!</h2> </div>
                    <div className="lensMeetSlider p-5">
                        <Slider {...ProductSettings}>
                            <div className="carousel-item active">
                                <div className="product-1 row fadeIN">
                                    <div className="col-12 col-lg-4"><img src={business_02} className="img-fluid" /></div>
                                    <div className="col-12 col-lg-4">
                                        <div className="card">
                                            <h6>OneXR</h6></div>
                                        <div>
                                            <h2 className="pro-h2">Empower contact center agents with visual guidance!</h2>
                                            <p className="pro-p"><b>Eliminate the confusion</b> and bring in successful assistance between the customers and customer support agencies. Technicians can easily access and guide the other end users simultaneously at ease. <b>Highly secured Web-based technologies</b> keep out other third-party apps and their download!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className=" product-2 row fadeIN">
                                    <div className="col-12 col-lg-4"><img src={business_01} className="img-fluid" /></div>
                                    <div className="col-12 col-lg-4">
                                        <div className="card">
                                            <h6>OneXR</h6></div>
                                        <div>
                                            <h2 className="pro-h2">TRANSFORM YOUR AFTER-SALES SERVICES, WITH OneXR meet, FOR SMEs!</h2>
                                            <p className="pro-p"><b>Increase the efficiency</b> of your after-sales service with the help of the AR video calling feature of One XR meet and <b>assist your client in real-time!</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="product-3 row fadeIN">
                                    <div className="col-12 col-lg-4"><img src={business_03} className="img-fluid" /></div>
                                    <div className="col-12 col-lg-4">
                                        <div className="card">
                                            <h6>OneXR</h6></div>
                                        <div>
                                            <h2 className="pro-h2">CUSTOMIZABLE SOLUTIONS CREATED FOR ENTERPRISES! </h2>
                                            <p className="pro-p"><b>High-tech customer service</b> with the <b>best interactive live experiences provided!</b>
                                                <br /> Foster client acknowledgment with <b>White-labelled Remote AR applications</b> for brands, items, areas, and clients. White-labeled Remote assisting apps help <b>construct trust and viability</b> among clients and enterprises.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>

                <div className="lens-contact-bg">
                    <div className="lens-contact-expo">
                        <div className="row " style={{ backgroundImage: `url(${banner_bg})` }}>
                            <div className="col-12 col-lg-3 ">
                                <h4>WE HAVE THE RIGHT PLAN FOR YOU!</h4>
                                <h3>The only solution you will need for your sales, marketing, and outreach needs!</h3>
                                <p>OneXR meet is available in ready-to-use subscription models. Our esteemed clients and renowned enterprises go with our customized and floating licensed packages! </p>
                                <div className="expoBtnDiv"><a href=""><i className="bi bi-check2"></i> START TRIAL</a> </div>
                            </div>
                            <div className="col-12 col-lg-6 "> <img src={laptops} className="" /> </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default LensPage;
