import React, { useEffect, useState } from 'react';

import kiosk_main from "../../assets/images/kiosk-images/kiosk_main.png";
import Group263 from "../../assets/images/homepage-images/Group263.svg";
import Group264 from "../../assets/images/homepage-images/Group264.svg";
import Group265 from "../../assets/images/homepage-images/Group265.svg";
import Group266 from "../../assets/images/homepage-images/Group266.svg";
import product_1 from "../../assets/images/kiosk-images/product-1.png";
import product_2 from "../../assets/images/kiosk-images/product-2.png";
import product_3 from "../../assets/images/kiosk-images/product-3.png";
import solution_1 from "../../assets/images/kiosk-images/solution-1.png";
import solution_2 from "../../assets/images/kiosk-images/solution-2.png";
import solution_3 from "../../assets/images/kiosk-images/solution-3.png";
import solution_4 from "../../assets/images/kiosk-images/solution-4.png";
import engineer from "../../assets/images/kiosk-images/engineer.png";
import kiosk_bg from "../../assets/images/kiosk-images/kiosk_bg.jpg";
import dots from "../../assets/images/ar-vr-images/dots.png";
import ar_vr_bg from "../../assets/images/ar-vr-images/ar-vr_bg.jpeg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



function KioskPage() {

    var ProductSettings = {
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed:1000,
        speed: 8000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                }
            }
        ]
    };
    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);

    return (
        <>
        <Helmet>
            <title>OneXR - Kiosk</title>
            <meta name="description" content="Take your OneXR's kiosk where ever you want! Place it anywhere and see your businesses thrive at your desired altitudes! Completely open & no restrictions!" />
        </Helmet>
            <div className="wrapper1">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="kiosk_main" style={{ backgroundImage: `url(${kiosk_bg})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-5 p-5 ">
                            <div className=" mt-5">
                                <h1>OneXR</h1>
                                <h2> Kiosk Solution</h2>
                                <p>Now you can participate in Expos and position yourselves at key places and <b>engage customers virtually and remotely.</b> </p>
                                <p>The <b>'kiosk'</b> is a revolutionary marketing solution that can help you <b>showcase</b> your product or service and even <b>generate leads</b> through our custom application.</p>
                                <p>You can still have your look and feel brand! </p>
                            </div>
                        </div>
                        <div className=" col-12 col-lg-5 "> <img src={kiosk_main} className="" /> </div>
                    </div>
                </div>

                <div className="benefits row m-0 pt-2 pb-2">
                    <div className="col-lg-3 col-md-3">
                        <h1>Beneficiaries of <br />OneXR</h1> </div>
                    <div className=" col-lg-9 col-md-9">
                        <div className="carousel grab">
                            <Slider {...ProductSettings}>
                                <div> <img src={Group263} />
                                    <p className="icon-para">Automotive</p>
                                </div>
                                <div> <img src={Group264} />
                                    <p className="icon-para">Construction</p>
                                </div>
                                <div> <img src={Group265} />
                                    <p className="icon-para">Maintanance & Repair</p>
                                </div>
                                <div> <img src={Group266} />
                                    <p className="icon-para">Manufatoring</p>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>

                <div className="ar-vr-products">
                    <div className="content-row1">
                        <div className="kioskContent">
                            <h2>Is your organization's main goal "To bring an effect on each client"</h2>
                                 <h2>or "figuring out how to make it simple for your representatives to hint at preparing modules?”</h2>
                            <h2>This is how your <b>kiosk</b> helps your business.</h2> 
                        </div>
                    </div>
                    <div className="card-row row container">
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_1} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Successful, user-friendly engagement!</h1>
                                    <p>No salesperson, no physical kiosk required — Engage with prospects in a more personal way, <b>increasing your sales and customer lifetime value.</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_2} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Unmanned, place it anywhere!</h1>
                                    <p>The free-standing Kiosk can be placed <b>anywhere with ease!</b> With perfect branding, showcase your brand or company and make prospects aware of your products and services!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_3} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Train employees on the go!</h1>
                                    <p>Create <b>interactive learning modules</b> with Kiosk for your employees to use on the go! A hands-on experience makes it easy for them to learn about your products and services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div>
                <hr style={{margin:"0 7%"}}/>
                </div> */}

                <div className="ar-vr-solution">
                    <div className="content-row2 kioskSolutions">
                        <div className="content2">
                            <h1>OneXR Kiosk application is a revolutionary new way of engaging and
                                educating attendees in your expos, events, or workplace.</h1> </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div>
                                <img src={solution_1} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                            </div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Bringing brick-and-mortar to digital space!
                                </h2>
                                <p className="pro-p">Businesses feel hard to bring up innovative ways to engage customers with their products/brands. Only mere <b>physical presence</b> won't help in creating the business presence.</p>
                                <p className="pro-p"> <b>Profile building and brand awareness</b> can be brought efficiently with OneXR's Kiosk solution. The Kiosk can be <b>placed anywhere globally</b> to bring your presence to your audience. The business story and history can be <b>showcased more engagingly.</b> A kiosk content can be customized according to the needs of the businesses and products/brands, enabling the users to make <b>quicker decisions</b> about the products.</p> 
                             </div>
                        </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Enhancing customer experience</h2>
                                <p className="pro-p">Buyers like to know more about the brand and products during the <b>pre-purchase phase,</b> and <b>self-served information</b> is the best way understand it well.</p>
                                
                                 <p className="pro-p">Businesses need to level up, leaving the old concepts in branding their products / brands. Deploying a revolutionary Kiosk helps the audience to clarify their doubts on the spot <b>without wasting time, waiting for sales reps.</b> This helps businesses to <b>close more sales</b> in a short time, <b>doubling up their conversion rate and reducing time in
                                    the business pipeline.</b> Kiosk helps your audience to <b> experience live,quick access
                                    to all your products without any delay.</b> No salesperson or rep is needed to give demos </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_2} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_3} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Advanced Analytics to support your marketing efforts!</h2>
                                <p className="pro-p">Businesses become trapped with <b>traditional
                                    marketing methods,</b> which fail to produce the intended <b>ROI.</b></p>
                                
                                    <p className="pro-p"><b>Increase engagement</b> with our interactive features, which allow you to <b>predict
                                        customer reactions in real time.</b> With our <b>CMS and comprehensive analytics,</b> define the future of your marketing efforts. The dashboard allows you to select from a variety of <b>KPIs</b> that are important to your company. The analytics assist you in achieving the desired results for your efforts</p>
                            </div>
                        </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Convenient and easy to use! </h2>
                                <p className="pro-p"><b>Expensive trade shows & events,</b> has made businesses to search for <b>innovative concepts</b> to leverage their <b>brand presence and engagement</b></p>
                                
                                <p className="pro-p"> The 'Kiosk' is one such solution where your audience can try their hands on exploring your brands and products instantly at any time. The installation is <b>simple and easy</b> to use by anyone on the go. You need not have spend huge on being present in all the events, go get your Kiosk, the easy to use solution can be <b>installed anywhere,
                                        anytime you need.</b> Just rent a 'Kiosk' and get started! </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_4} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                    </div>
                </div>

                <div className="contact-bg pb-4">
                    <div className="contact-expo ar_vr_expo">
                        <div className="row p-5" style={{ backgroundImage: `url(${ar_vr_bg})` }}>
                            <div className="col-12 col-lg-4">
                                <h3>Get your kiosk on its feet!</h3>
                                <p className='kioskExpo_P'>As an OneXR customer, you get the <b>flexibility to customize</b> the application according to your needs. You can make it <b>simple or complex</b> depending on your business and expertise!</p>
                                 <p>Let the <b>self-support Kiosks demonstrate your product</b> or service, and schedule appointments for you</p>
                                 <div className="expoBtnDiv"><Link to="/requestDemo"><i className="bi bi-check2"></i> Schedule a Demo</Link> </div>
                            </div>
                            <div className="col-12 col-lg-7"> <img src={engineer} className="kioskExpoImg" /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default KioskPage;
