import React, { Component, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import TopBar from "../lib/TopBar/TopBar";
import MainHomePage from "../components/MainHomePage";
import Loader from "../lib/Loader/Loader";
import Footer from "../lib/Footer/Footer";
import Company from "../components/Company";
import RequestDemo from "../components/RequestQuote";
import ContactPage from "../components/ContactPage";
import ARVRProduct from "../components/product/ARVRProduct";
import WebVirtualShowroom from "../components/product/WebVirtualShowroom";
import KioskPage from "../components/product/KioskPage";
import PrintARPage from "../components/product/PrintARPage";
import LensPage from "../components/product/LensPage";
import AllResources from "../components/resources/Resources";
import ResourcesDetails from "../components/resources/ResourcesDetails";
import BlogCategory from "../components/resources/productCategory/BlogCategory";
import { Helmet } from 'react-helmet';
import NoPage from "../components/NoPage";
import Swag from "../components/Swag";
import CoachPage from "../components/product/Coach";
import WebAR from "../components/WebAR";



function HomePage() {
  const [isLoading, setLoading] = useState(true);
  const [metaDescription, setMetaDescription] = useState("");
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);

  //   // Get the current URL
  // const currentUrl = window.location.href;

  // // Find the matching object in the JSON data based on the current URL
  // const matchedData = MetaJson.find(item => currentUrl.includes(item.Link));

  // // Update the meta description based on the matched object
  // if (matchedData) {
  //   setMetaDescription(matchedData.Description);
  // }

  }, []);

  // useEffect(() => {
  //   const currentUrl = window.location.href;
  //   const matchedData = MetaJson.find(item => currentUrl.includes(item.Link));
  //   console.log("matchedData",matchedData);
  //   if (matchedData) {
  //     setMetaDescription(matchedData.Description);
  //   }
  //   console.log("metadescription",metaDescription);

  // }, [location.pathname]);

 
  return (
    <>

        <Helmet>
          <meta name="description" content={metaDescription} />
        </Helmet>


      {isLoading == true ? (
        <Loader />
      ) : (
        <>
          <TopBar />
          <Routes>
            <Route exact path="/" element={<MainHomePage />}></Route>
            <Route exact path="/company" element={<Company />}></Route>
            <Route exact path="/requestDemo" element={<RequestDemo />}></Route>
            <Route exact path="/contact" element={<ContactPage />}></Route>
            <Route exact path="/ar-vr" element={<ARVRProduct />}></Route>
            <Route exact path="/web-virtual-showroom" element={<WebVirtualShowroom />}></Route>
            <Route exact path="/kiosk" element={<KioskPage />}></Route>
            <Route exact path="/print-ar" element={<PrintARPage />}></Route>
            <Route exact path="/lens" element={<LensPage />}></Route>
            {/* <Route exact path="/coach" element={<CoachPage />}></Route> */}
            <Route exact path="/resources" element={<AllResources />}></Route>
            <Route exact path="/resources-details/:id" element={<ResourcesDetails />}></Route>
            <Route exact path="/blog" element={<BlogCategory />}></Route>
            <Route exact path="*" element={<MainHomePage />}></Route>
            <Route exact path="/swag" element={<Swag />}></Route>
            <Route exact path="/webar/link" element={<WebAR />}></Route>
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}
export default HomePage;
