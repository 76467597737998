import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import resourseJson from "../../Json/resources.json"; 
import $ from "jquery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import axios from "axios";
import Shimmer from 'react-js-loading-shimmer';


function Resources() {

  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)


  const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);

  useEffect(() => {
    setLoading(true)
    axios.get("https://onexr.us:3000/api/v1/blog/getAllBlog", {
     
    }).then((data) => {
      setData(data.data.data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    })


    $(".navbar").css("background", "transparent");
    $(".navbar").css("box-shadow", "none");
    var btn = $("#button");
    const handleScroll = () => {
      if (window.pageYOffset > 500) {
        btn.addClass("show");
        $(".navbar").css("background", "#fff");
        $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")

      } else if (window.pageYOffset < 500) {
        btn.removeClass("show");
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none");
      }
    };
    window.addEventListener("scroll", handleScroll);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    btn.on('click', function (e) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [])

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     axios.get("https://onexr.us:3000/api/v1/blog/getAllBlog", {
  //       headers: {
  //       }
  //     }).then((data) => {
  //       setData(data.data.data)
  //       setLoading(false)
  //     }).catch((error) => {
  //       setLoading(false)
  //     })
  //   }, 5000);

  // }, [])

  const TrendingPostList = data
    .filter((item) => item.trending == "Yes") 
    .map((value, index) => {
      return (
      <div className="col-md-6 col-lg-3" key={index}>
        <div className="trendPostCard">
          <figure>

            <Link to={`/resources-details/${value.slug}` } state={{ id: value.id }}>
              <img src={value.thumbnail} className="res-imgTrend" />
            </Link>
            <div className="caseCategoryTag">
              {value.tag.map((tag) => {
                return <button><Link to="/blog" state={{ tagname: tag.tagname }}>{tag.tagname}</Link></button>
              })}

            </div>
          </figure>
          <Link to={`/resources-details/${value.slug}` } state={{ id: value.id }}>
            <h5>{value.name}</h5>
          </Link>
        </div>
      </div>        
      );
    });

  const PopularPostList = data
    .filter((item) => item.popular == "Yes")
    .map((value, index) => {
      return (
          <div className="popularPostCard" key={index}>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <figure>
                <Link to={`/resources-details/${value.slug}` } state={{ id: value.id }}>
                  <img src={value.thumbnail} className="res-imgTrend" />
                </Link>
              </figure>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Link to={`/resources-details/${value.slug}` } state={{ id: value.id }}>
                <h5> {value.name}</h5>
              </Link>
            </div>
          </div>
      );
    });

  const AllPostList = data
    .filter((item) => item.popular == "No" && item.trending == "No")
    .map((value, index) => {
      return (
        <div className="container">
          <div className="row resourseListRow">
            <div className="img-col col-md-12 col-lg-6">
              <Link to={`/resources-details/${value.slug}` } state={{ id: value.id }}>
                <figure>
                  <img src={value.thumbnail} className="res-imglistCards" />
                </figure>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="linkCardsDiv">
                <i className="bi bi-folder2-open"></i>
                {value.tag.map((tag) => {
                  return <p><Link to="/blog" state={{ tagname: tag.tagname }}>{tag.tagname}</Link> | </p>
                })}
              </div>
              <h1>
                <Link to={`/resources-details/${value.slug}` } state={{ id: value.id }}>{value.name} </Link>
              </h1>
              <p>{value.description}</p>
            </div>
          </div>
        </div>
      );
    });

    const randomArray = shuffleArray(data)
    .filter((item, index) => item.path != window.location.pathname)

    const resourceCorouselData = randomArray
    .filter((item, index) => index < 5)
    .map((value,index) => {
      return (
          <div className="carousel">
            <Slider {...ResourseTrendSlider}>
                <div>
                  <img src={value.image} className="trendpostSlider" />
                </div>
                <div>
                  <img src={value.image} className="trendpostSlider" />
                </div>
                <div>
                  <img src={value.image} className="trendpostSlider" />
                </div>
                <div>
                  <img src={value.image} className="trendpostSlider" />
                </div>
                <div>
                  <img src={value.image} className="trendpostSlider" />
                </div>
            </Slider>
          </div>
      );
    });

  var ResourseTrendSlider = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  return (
    <>
      <Helmet>
        <title>OneXR - Resources</title>
        <meta name="description" content="Virtual Reality (VR) technology is transforming various industries, and the manufacturing industry is no exception." />
      </Helmet>
      <div className="wrapper1">
        <a id="button">
          <i className="bi bi-arrow-up-square-fill"></i>
        </a>
        <div className="resource">
          <div className="resource_head">
            <h1>Resources</h1>
          </div>
          {/* ----- Trending Post ----- */}
          <div className="trendingPostDiv">
            <h3 className="mb-4">Trending Posts</h3>
            <div className="row container m-0 d-flex">
            {(isLoading ?
            <>
              <div className="col-md-6 col-lg-3">
                <div>
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5 className="text-center">
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                </div>
              </div> 
              <div className="col-md-6 col-lg-3">
                <div>
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                </div>
              </div> 
              <div className="col-md-6 col-lg-3">
                <div>
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                </div>
              </div> 
              <div className="col-md-6 col-lg-3">
                <div>
                  <div>      
                    <Shimmer  className={"trendImageLoader"}/>
                  </div> 
                  <h5>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                    <Shimmer className={"trendHeaderLoader"}/>
                  </h5>
                </div>
              </div> 
            </>
            : data && data.length > 0  && TrendingPostList )}
            </div>
          </div>
          <hr style={{ margin: "5% 17%" }}></hr>
          <div className="row  postContainer container m-auto">
            <div className="col-lg-8 col-12">
              {/* ----- Resourse List ----- */}
              {(isLoading ?
              <>
              <div className="container">
                <div className="row resourseListRow">
                  <div className="img-col col-md-12 col-lg-6">
                    <Shimmer  className={"postImageLoader"}  style={{height:"230px"}}/>
                  </div>
                  <div className="col-md-12 col-lg-6" style={{alignItems:"unset"}}>
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                    </div>
                    <div>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row resourseListRow">
                  <div className="img-col col-md-12 col-lg-6">
                    <Shimmer  className={"postImageLoader"}  style={{height:"230px"}}/>
                  </div>
                  <div className="col-md-12 col-lg-6" style={{alignItems:"unset"}}>
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                    </div>
                    <div>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row resourseListRow">
                  <div className="img-col col-md-12 col-lg-6">
                    <Shimmer  className={"postImageLoader"}  style={{height:"230px"}}/>
                  </div>
                  <div className="col-md-12 col-lg-6" style={{alignItems:"unset"}}>
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                    </div>
                    <div>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row resourseListRow">
                  <div className="img-col col-md-12 col-lg-6">
                    <Shimmer  className={"postImageLoader"}  style={{height:"230px"}}/>
                  </div>
                  <div className="col-md-12 col-lg-6" style={{alignItems:"unset"}}>
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                    </div>
                    <div>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row resourseListRow">
                  <div className="img-col col-md-12 col-lg-6">
                    <Shimmer  className={"postImageLoader"}  style={{height:"230px"}}/>
                  </div>
                  <div className="col-md-12 col-lg-6" style={{alignItems:"unset"}}>
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                    </div>
                    <div>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row resourseListRow">
                  <div className="img-col col-md-12 col-lg-6">
                    <Shimmer  className={"postImageLoader"}  style={{height:"230px"}}/>
                  </div>
                  <div className="col-md-12 col-lg-6" style={{alignItems:"unset"}}>
                    <div className="linkCardsDiv mb-3">
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                      <Shimmer className={"trendHeaderLoader"}/>
                    </div>
                    <div>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                        <p>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              </>
               :data && data.length > 0 && AllPostList )}
             
            </div>
            <div className="col-lg-4 col-12">
              {/* ----- popularResourseCarousel ----- */}
              <div className="popularResourseCarousel">
                <div className="carousel">
                  <Slider {...ResourseTrendSlider}>
                    {(isLoading ? 
                    <>
                      <Shimmer  className={"trendImageLoader"}/>
                    </>
                    :resourceCorouselData)}
                  </Slider>
                </div>
              </div>
              {/* ----- Popular Post ----- */}
              <div className="popularPostDiv">
                <h3>Popular Posts</h3>
                <div className="row container m-0 d-flex justify-content-center align-items-center">
                {(isLoading ?
                  <>
                    <div className="popularPostCard">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <figure> 
                        <Shimmer  className={"trendImageLoader"}/>
                        </figure>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 ms-4">
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                      </div>
                    </div>
                    <div className="popularPostCard">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <figure> 
                        <Shimmer  className={"trendImageLoader"}/>
                        </figure>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 ms-4">
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                      </div>
                    </div>
                    <div className="popularPostCard">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <figure> 
                        <Shimmer  className={"trendImageLoader"}/>
                        </figure>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 ms-4">
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                      </div>
                    </div>
                    <div className="popularPostCard">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <figure> 
                        <Shimmer  className={"trendImageLoader"}/>
                        </figure>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 ms-4">
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                          <Shimmer className={"trendHeaderLoader"}/>
                      </div>
                    </div>
                  </>
                  : data && data.length > 0  && PopularPostList )}  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Resources;

