import React, { useEffect, useState } from 'react';

import virtual_main from "../../assets/images/virtualshowroom-images/virtual_main.png";
import Group263 from "../../assets/images/homepage-images/Group263.svg";
import Group264 from "../../assets/images/homepage-images/Group264.svg";
import Group265 from "../../assets/images/homepage-images/Group265.svg";
import Group266 from "../../assets/images/homepage-images/Group266.svg";
import product_1 from "../../assets/images/virtualshowroom-images/product-1.png";
import product_2 from "../../assets/images/virtualshowroom-images/product-2.png";
import product_3 from "../../assets/images/virtualshowroom-images/product-3.png";
import solution_1 from "../../assets/images/virtualshowroom-images/solution-1.png";
import solution_2 from "../../assets/images/virtualshowroom-images/solution-2.png";
import solution_3 from "../../assets/images/virtualshowroom-images/solution3.png";
import solution_4 from "../../assets/images/virtualshowroom-images/solution-4.png";
import laptop from "../../assets/images/virtualshowroom-images/laptop.png";
import virtual_bg from "../../assets/images/virtualshowroom-images/virtual-bg.jpg";
import dots from "../../assets/images/ar-vr-images/dots.png";
import ar_vr_bg from "../../assets/images/ar-vr-images/ar-vr_bg.jpeg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



function WebVirtualShowroom() {

    var ProductSettings = {
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed:1000,
        speed: 8000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 300) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);

    return (
        <>
        <Helmet>
            <title>OneXR - Web Virtual Showroom</title>
            <meta name="description" content="The future of showrooms is here! Our amazing 'Virtual showroom' solution gives you the chance to experience hyper-reality! what your customers will experience when they visit your factory or showroom." />
        </Helmet>
            <div className="wrapper1">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="virtual_main" style={{ backgroundImage: `url(${virtual_bg})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-4 p-5 ">
                            <div className=" mt-5">
                                <h1>OneXR</h1>
                                <h2> Web Virtual Showroom</h2>
                                <p>Make your clients feel like they are there with you!</p> <p>A Virtual showroom, give users a sense of what it's like in the showroom. Show your visitors exactly what the product looks like and how the product is used. </p>
                            </div>
                        </div>
                        <div className=" col-12 col-lg-6 mt-5"> <img src={virtual_main} className="" /> </div>
                    </div>
                </div>

                <div className="benefits row ms-0 pt-2 pb-2" style={{marginTop:"-4%"}}>
                    <div className="col-lg-3 col-md-3">
                        <h1>Beneficiaries of <br />OneXR</h1> </div>
                    <div className=" col-lg-9 col-md-9">
                        <div className="carousel grab">
                            <Slider {...ProductSettings}>
                                <div> <img src={Group263} />
                                    <p className="icon-para">Automotive</p>
                                </div>
                                <div> <img src={Group264} />
                                    <p className="icon-para">Construction</p>
                                </div>
                                <div> <img src={Group265} />
                                    <p className="icon-para">Maintanance & Repair</p>
                                </div>
                                <div> <img src={Group266} />
                                    <p className="icon-para">Manufatoring</p>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>

                <div className="ar-vr-products">
                    <div className="content-row1">
                        <div className="content1">
                            <p>The future of showrooms is here! Our amazing <strong>'Virtual showroom'</strong>
                                solution gives you the chance to experience hyper-reality!</p>
                                <p>what your customers will experience
                                when they visit your factory or showroom.</p>
                        </div>
                    </div>
                    <div className="card-row row pb-5 container">
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_1} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Engage your customers and increase web traffic</h1>
                                    <p>We create an <strong>interactive experience,</strong> giving you the tools to <strong>engage</strong> with your visitors in a meaningful way so that they'll <strong>spend more time on your site,</strong> nurturing your audience and <strong>improving website traffic.</strong> </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_2} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Drive more attention and increased visibility to your website!</h1>
                                    <p>OneXR gives you an <strong>online presence</strong> and can provide leads for your businesses. Your website traffic will increase rapidly with <strong>optimized searches.</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 m-4">
                            <div className="card-container">
                                <div> <img src={product_3} className="pro-img" /> </div>
                                <div className="card-content">
                                    <h1>Make your website more appealing!</h1>
                                    <p>OneXR offers advanced features that <strong>
                                        engage visitors
                                        and enhance the experience
                                    </strong> of consuming information on your website.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <hr  style={{margin:"0 7%"}} />
                </div>

                <div className="webVirtualSolution">
                    <div className="content-row2">
                        <div className="content2">
                            <h1>We offer a variety of opportunities to increase your digital presence,
                                drive traffic and audience engagement on your website.</h1> </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_1} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Make your Audience experience the future of showrooms!
                                </h2>
                                <p className="pro-p">Achieving <strong>quick audience attention,</strong> getting customers convinced and making them buy your products might be the trickiest task for the business By deploying Web Virtual showrooms businesses can easily garner the prospects by <strong>increasing the website traffic,
                                    stickiness rate and customer engagement instantly!</strong> Let your audience <strong>engage and enjoy the real simulation and immersive experience</strong> with <strong>3D product
                                        animation with a 360-degree virtual tour</strong> and demonstrative videos curbing, the need for arranging expensive trade shows and demos!</p> 
                                </div>
                        </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Give your prospects an immersive experience through your website</h2>
                                <p className="pro-p">Many businesses fail to own <strong>interactive and immersive websites,</strong> offering the complete features of products and find difficulties in producing engaging quality demos to their customers. Product demos must be more <strong>engaging and attract the customers</strong> to decide and get convinced to make a buy! It must <strong>enrich demos</strong> about the products with interactive modules to converse with the buyers so that it <strong>will increase the sales conversion rate and reducing the sales cycle.</strong> Opting for <strong>360-degree Web virtual showrooms</strong> helps the customers to get a <strong>quick sneak-peak
                                        and get into instant buying!</strong> </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_2} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_3} id="ani-img" className="ani-images img-fluid " data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Make your marketing efforts data-driven!</h2>
                                <p className="pro-p">Businesses get stuck with their marketing and fumble with the traditional marketing strategies, failing to yield the <strong>desired results.</strong> <strong>Increase engagement</strong> with our interactive features that allow you to estimate customer reactions in real-time to help shape the future of your <strong>marketing strategies</strong> through our <strong>CMS and advanced analytics.</strong> The dashboard enables you to choose from a <strong>wide range of metrics</strong> that matter most for your business. The analytics you make an account, helps you <strong>drive the desired results you target for your customers!</strong></p>
                            </div>
                        </div>
                    </div>
                    <div className="solution-row row container">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Engage your customers through an immersive website
                                </h2>
                                <p className="pro-p"><strong>Business growth</strong> often takes place at breakneck speed, as they miss out on <strong>fantastic opportunities</strong> to make their <strong>brand and product visible to everyone</strong> and the first step to move towards this direction through having an <strong>intuitive and informative website.</strong> Deploying OneXR's Virtual showroom with its New-Gen technologies <strong>
                                        transform your
                                        simple and traditional website
                                    </strong> into <strong>lead generating platform</strong> as it helps businesses to interact directly and explain the specs of the products, anchors a <strong>
                                        deep immersive
                                        engagement
                                    </strong> and helps prospects, to communicate with your brand,<strong> anytime and from anywhere!</strong> </p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div><img src={solution_4} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" /></div>
                        </div>
                    </div>
                </div>

                <div className="contact-bg pb-4">
                    <div className=" webVirtualExpo contact-expo">
                        <div className="row p-5" style={{ backgroundImage: `url(${ar_vr_bg})` }}>
                            <div className="col-12 col-lg-4 ">
                                <h3>The future of marketing is here now! </h3>
                                <p>We revolutionize the ways <strong>brands reach</strong> out to their audience by providing <b>stunning 3D product demos,</b> <b>interactive content, and analytics</b> based on industry insights to <b>transform your business.</b></p> 
                                <div className="expoBtnDiv"><Link to="/contact"><i className="bi bi-check2"></i> Contact Us</Link> </div>
                            </div>
                            <div className="col-12 col-lg-7 "> <img src={laptop} className="" /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebVirtualShowroom;
