import React from 'react';



function NoPage() {


    return (
        <>
            <div className='noPageError'>
                <h1>404</h1>
                <h2>Page Not Found</h2>
            </div>
        </>
    );
}

export default NoPage;

