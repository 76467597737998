import React, { useEffect, useState } from 'react';

import lens_main from "../../assets/images/lens-images/ONE-XR-LENS-BANNER-01.png";
import Group263 from "../../assets/images/homepage-images/Group263.svg";
import Group264 from "../../assets/images/homepage-images/Group264.svg";
import Group265 from "../../assets/images/homepage-images/Group265.svg";
import Group266 from "../../assets/images/homepage-images/Group266.svg";
import product_1 from "../../assets/images/kiosk-images/product-1.png";
import product_2 from "../../assets/images/kiosk-images/product-2.png";
import product_3 from "../../assets/images/kiosk-images/product-3.png";
import solution_1 from "../../assets/images/kiosk-images/solution-1.png";
import solution_2 from "../../assets/images/kiosk-images/solution-2.png";
import solution_3 from "../../assets/images/kiosk-images/solution-3.png";
import solution_4 from "../../assets/images/kiosk-images/solution-4.png";
import engineer from "../../assets/images/kiosk-images/engineer.png";
import kiosk_bg from "../../assets/images/kiosk-images/kiosk_bg.jpg";
import dots from "../../assets/images/ar-vr-images/dots.png";
import ar_vr_bg from "../../assets/images/ar-vr-images/ar-vr_bg.jpeg";
import CustomerExperience from '../../assets/images/homepage-images/CustomerExperience.png';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



function CoachPage() {

    var ProductSettings = {
        infinite: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    useEffect(() => {
        $(".navbar").css("background", "transparent");
        $(".navbar").css("box-shadow", "none")
        setTimeout(() => {
            AOS.init();
        }, 2000);
        var btn = $('#button');
        const handleScroll = () => {
            if (window.pageYOffset > 500) {
                btn.addClass("show");
                $(".navbar").css("background", "#fff");
                $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
            }
            else if (window.pageYOffset < 500) {
                btn.removeClass("show");
                $(".navbar").css("background", "transparent");
                $(".navbar").css("box-shadow", "none")
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        btn.on('click', function (e) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });

    }, []);

    return (
        <>
            <Helmet>
                <title>OneXR - Coach</title>
                <meta name="description" content="Take your OneXR's kiosk where ever you want! Place it anywhere and see your businesses thrive at your desired altitudes! Completely open & no restrictions!" />
            </Helmet>
            <div className="wrapper1">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="kiosk_main" style={{ backgroundImage: `url(${kiosk_bg})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-6 p-5 ">
                            <div className=" mt-5">
                                <h1 style={{color:'#656363'}}>OneXR</h1>
                                <h1>Experience the Future of Training!</h1>
                                <h2 className='pt-2'>Transform the Way You Enhanced Skills, Boost Operational Efficiency and Create a Proficient Workforce</h2>
                                <p>Welcome to the future of manufacturing, where innovation meets efficiency. Our OneXR Coach is revolutionizing the industry, offering tailored solutions to empower your workforce, enhance safety, and elevate operational efficiency.</p>
                            </div>
                        </div>
                        <div className=" col-12 col-lg-5 "> <img src={lens_main} className="" /> </div>
                    </div>
                </div>

                <div className="benefits row m-0 pt-2 pb-2">
                    <div className="col-lg-3 col-md-3">
                        <h1>Beneficiaries of <br />OneXR</h1> </div>
                    <div className=" col-lg-9 col-md-9">
                        <div className="carousel grab">
                            <Slider {...ProductSettings}>
                                <div> <img src={Group263} />
                                    <p className="icon-para">Automotive</p>
                                </div>
                                <div> <img src={Group264} />
                                    <p className="icon-para">Construction</p>
                                </div>
                                <div> <img src={Group265} />
                                    <p className="icon-para">Maintanance & Repair</p>
                                </div>
                                <div> <img src={Group266} />
                                    <p className="icon-para">Manufatoring</p>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>

                <div className="products">
                    <div className="pro-content">
                        <div className="content-prop">
                            <h1 style={{ color: "#1494d5" }}>What do our</h1> <h1 style={{ color: "#213457" }}> Coach offerings provide to</h1> <h1 style={{ color: "#213457" }}>  you?</h1>
                            <hr></hr>
                        </div>
                    </div>
                    <div className="auto-slide row mx-auto grab">
                        <Slider {...ProductSettings} className="carousel">
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Immersive Learning Environments</div>
                                            <div className="card-text">
                                                <p>OneXR Coach create realistic and immersive training scenarios, allowing your employees to learn in simulated environments that closely resemble their actual work conditions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Hands-on Experience</div>
                                            <div className="card-text">
                                                <p>Employees can gain hands-on experience in a safe virtual environment, practicing complex tasks and procedures without the risk of damage to equipment or harm to themselves. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">
                                                Efficient Onboarding
                                            </div>
                                            <div className="card-text">
                                                <p>OneXR Coach expedites the onboarding process by providing new hires with comprehensive virtual training sessions, enabling them to familiarize themselves with machinery, processes, and safety protocols.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Safety Training</div>
                                            <div className="card-text">
                                                <p>OneXR Coach simulations are created for the safety training, allowing employees to experience and respond to hazardous situations without real-world consequences, improving safety awareness and preparedness.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Remote Assistance</div>
                                            <div className="card-text">
                                                <p>OneXR Coach allows for remote assistance, enabling employees in different locations to resolve complex tasks optimizing the time and cost investments</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Equipment Maintenance</div>
                                            <div className="card-text">
                                                <p>OneXR Coach can be used to train maintenance personnel in virtual environments, simulating equipment breakdowns and repairs to enhance troubleshooting skills and reduce downtime. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Product Assembly</div>
                                            <div className="card-text">
                                                <p>Manufacturing companies can use OneXR Coach to train workers in product assembly processes, in the assembling lines to ensure accuracy, efficiency, and compliance with quality standards.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>

                <div>
                    <hr style={{ margin: "0 7%" }} />
                </div>

                <div className="ar-vr-solution">
                    <div className="content-row2 kioskSolutions">
                        <div className="content2">
                            <h1 style={{ margin: '0 10%' }}>To encourage visitors to explore the benefits and features & contact for a demonstration</h1> </div>
                    </div>
                    <div className="kioskSolutionRow solution-row row container">
                        <div className="text-column col-12 col-lg-6">
                            <div className="card">
                                <h6>OneXR</h6></div>
                            <div>
                                <h2 className="pro-h2">Customized Training Modules</h2>
                                <p className="pro-p">OneXR Coach allows for the creation of customized training modules tailored to specific manufacturing processes, machinery, or industry requirements.</p>
                                <h2 className="pro-h2">Continuous Learning</h2>
                                <p className="pro-p">OneXR Coach enables continuous learning through regular updates and additions to training modules, ensuring that employees stay current with the latest technologies and best practices.</p>
                                <h2 className="pro-h2">Enhanced Engagement</h2>
                                <p className="pro-p">Immersive training experiences captivate learners, leading to increased engagement and knowledge retention compared to traditional training methods. </p>
                                <h2 className="pro-h2">Performance Analytics</h2>
                                <p className="pro-p">OneXR Coach analytics tools, provide insights into employee performance, allowing managers to identify areas for improvement and track the effectiveness of training programs.</p>
                                <h2 className="pro-h2">Leveraging XR Training & Service</h2>
                                <p className="pro-p">By leveraging XR Training & Service, manufacturing companies can enhance employee skills, improve operational efficiency, reduce training costs, and contribute to a safer and more productive work environment.</p>
                            </div>
                        </div>
                        <div className="img-column col-12 col-lg-6" style={{ backgroundImage: `url(${dots})` }}>
                            <div>
                                <img src={solution_1} id="ani-img" className="ani-images img-fluid " data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset="150" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-bg pb-4">
                    <div className="contact-expo ar_vr_expo">
                        <div className="row p-5" style={{ backgroundImage: `url(${ar_vr_bg})` }}>
                            <div className="col-12 col-lg-4">
                                <h3>Get your kiosk on its feet!</h3>
                                <p className='kioskExpo_P'>As an OneXR customer, you get the <b>flexibility to customize</b> the application according to your needs. You can make it <b>simple or complex</b> depending on your business and expertise!</p>
                                <p>Let the <b>self-support Kiosks demonstrate your product</b> or service, and schedule appointments for you</p>
                                <div className="expoBtnDiv"><Link to="/requestDemo"><i className="bi bi-check2"></i> Schedule a Demo</Link> </div>
                            </div>
                            <div className="col-12 col-lg-7"> <img src={engineer} className="kioskExpoImg" /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CoachPage;
