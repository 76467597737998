
import React, { useEffect, useState } from 'react';
import homeimage1 from '../assets/images/homepage_json/homeimage-1.png';
import backgroundimg from '../assets/images/homepage-images/backgroundimg.jpg';
import worldmap from '../assets/images/homepage-images/worldmap.png';
import hm1 from '../assets/images/homepage_json/hm-1-1.png';
import hm12 from '../assets/images/homepage_json/hm-1-2.png';
import homeimage2 from '../assets/images/homepage_json/homeimage-2.png';
import hm21 from '../assets/images/homepage_json/hm-2-1.png';
import hm22 from '../assets/images/homepage_json/hm-2-2.png';
import homeimage3 from '../assets/images/homepage_json/homeimage-3.png';
import hm31 from '../assets/images/homepage_json/hm3-1.png';
import homeimage4 from '../assets/images/homepage_json/homeimage-4.png';
import hm41 from '../assets/images/homepage_json/hm4-1.png';
import TestimonialVideo from '../assets/OneXRTestimony.mp4';
import CustomerExperience from '../assets/images/homepage-images/CustomerExperience.png';
import SalesandMarketing from '../assets/images/homepage-images/SalesandMarketing.png';
import ShowcasingProductsAsset4 from '../assets/images/homepage-images/ShowcasingProductsAsset4.png';
import TrainingAsset5 from '../assets/images/homepage-images/TrainingAsset5.png';
import AftersalesServicesAsset from '../assets/images/homepage-images/AftersalesServicesAsset.png';
import MaintenanceandtroubleshootingAsset from '../assets/images/homepage-images/MaintenanceandtroubleshootingAsset.png';
import Group263 from '../assets/images/homepage-images/Group263.svg';
import Group264 from '../assets/images/homepage-images/Group264.svg';
import Group265 from '../assets/images/homepage-images/Group265.svg';
import Group266 from '../assets/images/homepage-images/Group266.svg';
import Group23772 from '../assets/images/homepage-images/Group-23772.jpg';
import Group18676 from '../assets/images/homepage-images/Group18676.jpg';
import Group18677 from '../assets/images/homepage-images/Group18677.jpg';
import MaskGroup10 from '../assets/images/homepage-images/MaskGroup10.jpg';
import MaskGroup8 from '../assets/images/homepage-images/MaskGroup8.jpg';
import caterpillar from "../assets/images/homepage-images/caterpillar.png";
import dell from "../assets/images/homepage-images/dell.png";
import philips from "../assets/images/homepage-images/philips.png";
import samsung from "../assets/images/homepage-images/samsung.png";
import luminous from "../assets/images/homepage-images/luminous.png";
import havells from "../assets/images/homepage-images/havells.png";
import spartan from "../assets/images/homepage-images/spartan.jpg";
import suba from "../assets/images/homepage-images/suba.png";
import rrkabels from "../assets/images/homepage-images/rrkabels.png";
import ashokleyland from "../assets/images/homepage-images/ashokleyland.png";
import expoimg from "../assets/images/homepage-images/expo-img.png";
import testimonial_image from "../assets/images/homepage-images/testimonial-image-overlay.png";
import testimonial_polar_circle from "../assets/images/homepage-images/testimonial-polar-circle.webp";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import Loader from '../lib/Loader/Loader';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';


function MainHomePage() {



    const [sliderData, setSliderData] = useState([]);
    const [sliderMain, setSliderMain] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isSlider, setIsSlider] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get("https://onexr.us:3000/api/v1/slider/getSlider", {

        }).then((data) => {
            setSliderData(data.data.data)
            setIsSlider(true)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })
    }, [])

    var ProductSettings = {
        infinite: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    var CompaniesList = {
        infinite: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    var BenefitSettings = {
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 8000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const handleMouseEnter = (buttonName, objectName) => {
        // var active = document.getElementsByClassName('active-btn');
        // var current = document.getElementsByClassName(buttonName);

        // active[0].className = active[0].className.replace("active-btn", "");
        // current[0].className += " active-btn";

        // $(objectName).removeClass("hide").siblings().addClass("hide");
    };

    useEffect(() => {
        if (isSlider) {
            $(".navbar").css("background", "transparent");
            $(".navbar").css("box-shadow", "none")
            var btn = $('#button');
            const handleScroll = () => {
                if (window.pageYOffset > 500) {
                    btn.addClass("show");
                    $(".navbar").css("background", "#fff");
                    $(".navbar").css("box-shadow", "0px 15px 10px -15px #111")
                }
                else if (window.pageYOffset < 500) {
                    btn.removeClass("show");
                    $(".navbar").css("background", "transparent");
                    $(".navbar").css("box-shadow", "none")
                }
            };
            window.addEventListener('scroll', handleScroll);

            btn.on('click', function (e) {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            });

            $('.d-btn').hover(function () {
                $(this).parent().find('.d-btn').removeClass('active-btn');
                var show = $(this).data('show');
                $(show).removeClass("hide").siblings().addClass("hide");
                $(this).addClass('active-btn');
            });

            $('.d-btn').on('click', function () {
                $(this).parent().find('.d-btn').removeClass('active-btn');
                var show = $(this).data('show');
                $(show).removeClass("hide").siblings().addClass("hide");
                $(this).addClass('active-btn');
            });

            var current = 0,
                firstSlideIndex = true,
                slides = document.getElementsByClassName("main-container");
            console.log("sliders", slides);
            slides[current].style.display = 'block';
            var slideInterval = setInterval(function () {
                for (var i = 0; i < slides.length; i++) {
                    slides[i].style.display = 'none';
                }
                current = (current != slides.length - 1) ? current + 1 : 0;
                slides[current].style.display = 'block';
            }, 5000);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            return () => clearInterval(slideInterval);

        }

    }, [isSlider]);


    function stringFind(str1, str2, position) {
        if (str1.indexOf(position) == -1) {
            return false
        }
        var str = str2.split("-");
        var con = str[0] + "-" + str[1];
        var find = str1.indexOf(con)
        console.log("find", find);
        return find > -1 ? true : false
    }

    return (
        <>
            {isLoading && <Loader />}
            <Helmet>
                <title>OneXR - Home</title>
                <meta name="description" content="Your One-Stop Solution to the new Digital Virtual Sales and Marketing! An Augmented, Virtual Reality suite of products is all you would need for your Sales" />
            </Helmet>
            <div className="wrapper">
                <a id="button"><i className="bi bi-arrow-up-square-fill"></i></a>
                <div className="main" style={{ backgroundImage: `url(${backgroundimg})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <div className="container">
                                <h1>OneXR</h1>
                                <h4>
                                    Your One-Stop Solution to the new Digital Virtual Sales and
                                    Marketing!
                                    <br />
                                    <br />
                                    An Augmented, Virtual Reality suite of products is all you would
                                    need for your Sales, Marketing, Training, and services to
                                    <strong> increase sales</strong> in the digital era,
                                    <b> Reduce sales cycle and cost</b> and
                                    <b> increase services efficiency.</b>
                                </h4>
                            </div>
                        </div>
                        <div className="imgchange col-12 col-lg-6">
                            {sliderData && sliderData.length > 0 && sliderData.map((value, index) => (
                                <div className="main-container mt-5 fadein">
                                    <div className="mainimg fadeout">
                                        {<img src={value.main} className="img1" />}
                                        <a href='' className="rotatein">
                                            {value.bottom ? <img src={value.bottom} className="img2" /> :  ""}{value.right ? <img src={value.right} className="img3" /> : ""}{value.center ? <img src={value.center} className="img2-4" /> : ""}{value.left ? <img src={value.left} className="img2-2" /> : ""}
                                        </a>


                                    </div>
                                </div>
                            ))}

                            {/* <div className="main-container mt-5 fadein">
                                <div className="mainimg fadeout">
                                    <img src={homeimage1} className="img1" />
                                    <a href="" className="rotatein">
                                        <img src={hm1} className="img2" />
                                        <img src={hm12} className="img3" />
                                    </a>
                                </div>
                            </div>
                            <div className="main-container mt-5 fadein">
                                <div className="mainimg fadeout">
                                    <img src={homeimage2} className="img1-2" />
                                    <a href="" className="rotatein">
                                        <img src={hm21} className="img2-2" />
                                        <img src={hm22} className="img3-2" />
                                    </a>
                                </div>
                            </div>
                            <div className="main-container mt-5 fadein">
                                <div className="mainimg fadeout">
                                    <img src={homeimage3} className="img1-3" />
                                    <a href="" className="rotatein">
                                        <img src={hm31} className="img2-3" />
                                    </a>
                                </div>
                            </div>
                            <div className="main-container mt-5 fadein">
                                <div className="mainimg fadeout">
                                    <img src={homeimage4} className="img1-4" />
                                    <a href="" className="rotatein">
                                        <img src={hm41} className="img2-4" />
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="products">
                    <div className="pro-content">
                        <div className="content-prop">
                            <h2>
                                <center>With the fast-changing Digital/ Virtual businesses transaction, Manufacturers </center>
                                <center> are to adopt these new digital conversations to grow, </center>
                                <center>so are the challenges related to productivity and to conduct </center>
                                <center>sales virtually , it needs a different approach.</center>
                            </h2>
                            <h1 style={{ color: "#1494d5" }}>What do our</h1> <h1 style={{ color: "#213457" }}>product offerings provide to</h1> <h1 style={{ color: "#213457" }}>  you?</h1>
                            <hr></hr>
                        </div>
                    </div>
                    <div className="auto-slide row mx-auto grab">
                        <Slider {...ProductSettings} className="carousel">
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={CustomerExperience} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Customer Experience</div>
                                            <div className="card-text">
                                                <p>
                                                    Engage and enhance customers with immersive experiences
                                                    that create lasting memories without investing in new
                                                    euiptment
                                                    or training staff. Improve customer satisfaction and
                                                    retention by bridging the gap between physical and
                                                    digital words
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={SalesandMarketing} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Sales & Marketing</div>
                                            <div className="card-text">
                                                <p>
                                                    Convert your static websites into dynamic
                                                    3D virtual showrooms explaining with
                                                    visual experiences, providing a deeper understanding of
                                                    your product necessary enough to
                                                    create interest, and let our AI Chatbots schedule
                                                    meetings

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={ShowcasingProductsAsset4} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">
                                                Products immersive Demonstration
                                            </div>
                                            <div className="card-text">
                                                <p>
                                                    OneXR will help you create high-impact, products that
                                                    create stunning 360-degree product views, eye-catching
                                                    3D product explanations using animations simulating
                                                    all scenarios
                                                    that are not possible even in real-time scenarios in
                                                    your sales conversation .
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={TrainingAsset5} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Training</div>
                                            <div className="card-text">
                                                <p>
                                                    No more training facilities and products and experts
                                                    needed.
                                                    With Virtual Realitysolutions, providetraining an immersive
                                                    experience on-demand, any time,
                                                    anywhere.
                                                    \ Test and train your staff on all scenarios that are
                                                    not possible in real-time training scenarios.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={AftersalesServicesAsset} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">After-Sales Services</div>
                                            <div className="card-text">
                                                <p>
                                                    OneXR offers an intuitive
                                                    smartphone-based platform for repair services
                                                    with many beneficial features. Augmented reality
                                                    functions and chat tools help the frontline staff
                                                    resolve customer complaints in real-time to improve
                                                    customer satisfaction
                                                    and enhance after-sales support virtually.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="card">
                                    <div className="card-header">
                                        <img src={MaintenanceandtroubleshootingAsset} />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="card-title">Maintenance & Troubleshooting</div>
                                            <div className="card-text">
                                                <p>
                                                    OneXR offers an intuitive
                                                    smartphone-based platform for repair services
                                                    with many beneficial features. Augmented reality
                                                    functions and chat tools help the frontline staff
                                                    resolve customer complaints in real-time to improve
                                                    customer satisfaction
                                                    and enhance after-sales support virtually.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>

                <div className="benefits row m-0 pt-3 pb-3">
                    <div className="col-lg-3 col-md-3">
                        <h1>Beneficiaries of <br />OneXR</h1>
                    </div>
                    <div className="marquee col-lg-9 col-md-9">
                        <div className="carousel grab">
                            <Slider {...BenefitSettings}>
                                <div>
                                    <img src={Group263} />
                                    <p className="icon-para">Automotive</p>
                                </div>
                                <div>
                                    <img src={Group264} />
                                    <p className="icon-para">Construction</p>
                                </div>
                                <div>
                                    <img src={Group265} />
                                    <p className="icon-para">Maintanance & Repair</p>
                                </div>
                                <div>
                                    <img src={Group266} />
                                    <p className="icon-para">Manufatoring</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="solution">
                    <div className="sol-content">
                        <div style={{ width: "17%" }}>
                            <p className="p">OneXR</p>
                        </div>
                        <div>
                            <h3>Our Products & Solutions</h3>
                        </div>
                        <div>
                            <h2>
                                Enable fast and targeted business growth with solutions aimed at the end-user
                            </h2>
                        </div>
                    </div>
                    <div className='productSolution'>
                        <div className="row ps-5 pe-5">
                            <div className="col-12 col-lg-4">
                                <div className="btn-container ms-4 me-4">
                                    <button className="d-btn button-1 active-btn" data-show=".product-1"
                                        onMouseEnter={() => handleMouseEnter('button-1', '.product-1')}>
                                        <h3>OneXR</h3>
                                        <h1>AR/VR SOLUTION</h1>
                                    </button>
                                    <button className="d-btn button-2" data-show=".product-2"
                                        onMouseEnter={() => handleMouseEnter('button-2', '.product-2')}>
                                        <h3>OneXR</h3>
                                        <h1>WEB VIRTUAL SHOWROOM</h1>
                                    </button>
                                    <button className="d-btn button-3" data-show=".product-3"
                                        onMouseEnter={() => handleMouseEnter('button-3', '.product-3')}>
                                        <h3>OneXR</h3>
                                        <h1>KIOSK SOLUTION</h1>
                                    </button>
                                    <button className="d-btn button-4" data-show=".product-4"
                                        onMouseEnter={() => handleMouseEnter('button-4', '.product-4')}>
                                        <h3>OneXR</h3>
                                        <h1>PRINT AR</h1>
                                    </button>
                                    <button className="d-btn button-5" data-show=".product-5"
                                        onMouseEnter={() => handleMouseEnter('button-5', '.product-5')}>
                                        <h3>OneXR</h3>
                                        <h1>LENS</h1>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 solutionTabContent">
                                <div className="product-1 fadeIN">
                                    <div>
                                        <img src={Group23772} className="img-fluid mb-5" />
                                    </div>
                                    <span className="editHeading"> OneXR</span>
                                    <div>
                                        <h2 className="pro-h2">AR/VR Solution</h2>
                                        <p className="pro-p">
                                            Revolutionize the way customers get insight into your product!
                                            With our AR/VR application,you can
                                            your products and obtain greater reach using
                                            smartphones and tablets.
                                        </p>
                                        <Link to="/ar-vr">
                                            <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="product-2 hide fadeIN">
                                    <div>
                                        <img src={Group18676} className="img-fluid mb-5" />
                                    </div>
                                    <span className="editHeading"> OneXR</span>
                                    <div>
                                        <h2 className="pro-h2">Web Virtual Showroom</h2>
                                        <p className="pro-p">
                                            Convert your static websites into showrooms,
                                            generate leads,
                                            and let the Chatbot schedule appointments for
                                            your sales reps. Go beyond the
                                            limits of time and space and sell your products without any
                                            limitations.
                                        </p>
                                        <Link to="/web-virtual-showroom">
                                            <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="product-3 hide fadeIN">
                                    <div>
                                        <img src={MaskGroup10} className="img-fluid mb-5" />
                                    </div>
                                    <span className="editHeading"> OneXR</span>
                                    <div>
                                        <h2 className="pro-h2">Kiosk Solution</h2>
                                        <p className="pro-p">
                                            Be there in Expos and locations without a need to
                                            travel
                                            (Reduce Cost and
                                            increase sales). Let your branded Kiosks demonstrate products
                                            and provide
                                            immersive experiences and
                                            you engage them remotely in virtual reality
                                            or
                                            virtually. Let the
                                            self-support Kiosks demonstrate your product
                                            or service, and
                                            schedule appointments for you.
                                        </p>
                                        <Link to="/kiosk">
                                            <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="product-4 hide fadeIN">
                                    <div>
                                        <img src={MaskGroup8} className="img-fluid mb-5" />
                                    </div>
                                    <span className="editHeading"> OneXR</span>
                                    <div>
                                        <h2 className="pro-h2">Print AR</h2>
                                        <p className="pro-p">
                                            Let your static prints and giveaways demonstrate your
                                            products.
                                            Print AR is a powerful, interactive, and engaging way to
                                            showcase your products or brand in all
                                            prints and promotional giveaways generating leads.
                                        </p>
                                        <Link to="/print-ar">
                                            <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="product-5 hide fadeIN">
                                    <div>
                                        <img src={Group18677} className="img-fluid mb-5" />
                                    </div>
                                    <span className="editHeading"> OneXR</span>
                                    <div>
                                        <h2 className="pro-h2">LENS</h2>
                                        <p className="pro-p">
                                            Interact in real-time with service reps via Augmented
                                            reality video calls deterring
                                            the miscellaneous costs.
                                            Also, the AR 3D annotations, pointers, and doodles during

                                            the phone calls are the added brownies! These features enable
                                            you to get veteran
                                            advice from experts right from home!
                                        </p>
                                        <Link to="/lens">
                                            <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="solution2">
                    <div className="justify-content-center align-items-center p-5">
                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <Slider {...ProductSettings}>
                                    <div className="carousel-item active">
                                        <div className="product-1 fadeIN">
                                            <div>
                                                <img src={Group23772} className="img-fluid mb-5" />
                                            </div>
                                            <span className="editHeading"> OneXR</span>
                                            <div>
                                                <h2 className="pro-h2">AR/VR Solution</h2>
                                                <p className="pro-p">
                                                    Revolutionize the way customers get insight into your
                                                    product! With our AR/VR application,you can
                                                    your products and obtain greater reach
                                                    using smartphones and tablets.
                                                </p>
                                                <Link to="/ar-vr">
                                                    <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-2 fadeIN">
                                            <div>
                                                <img src={Group18676} className="img-fluid mb-5" />
                                            </div>
                                            <span className="editHeading"> OneXR</span>
                                            <div>
                                                <h2 className="pro-h2">Web Virtual Showroom</h2>
                                                <p className="pro-p">
                                                    Convert your static websites into showrooms,
                                                    generate leads,
                                                    and let the
                                                    Chatbot schedule appointments for your
                                                    sales reps. Go beyond the
                                                    limits of time and space and sell your products without
                                                    any limitations.
                                                </p>
                                                <Link to="/web-virtual-showroom">
                                                    <button className="pro-btn">Get Started <i className="bi bi-arrow-right-short"></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-3 fadeIN">
                                            <div>
                                                <img src={MaskGroup10} className="img-fluid mb-5" />
                                            </div>
                                            <span className="editHeading"> OneXR</span>
                                            <div>
                                                <h2 className="pro-h2">Kiosk Solution</h2>
                                                <p className="pro-p">
                                                    Be there in Expos and locations without a need to
                                                    travel
                                                    (Reduce Cost and
                                                    increase sales). Let your branded Kiosks demonstrate
                                                    products and provide
                                                    immersive experiences and
                                                    you engage them remotely in virtual reality
                                                    or
                                                    virtually. Let the
                                                    self-support Kiosks demonstrate your product
                                                    or service, and
                                                    schedule appointments for you.
                                                </p>
                                                <Link to="/kiosk">
                                                    <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-4 fadeIN">
                                            <div>
                                                <img src={MaskGroup8} className="img-fluid mb-5" />
                                            </div>
                                            <span className="editHeading"> OneXR</span>
                                            <div>
                                                <h2 className="pro-h2">Print AR</h2>
                                                <p className="pro-p">
                                                    Let your static prints and giveaways demonstrate your
                                                    products.
                                                    Print AR is a powerful, interactive, and engaging
                                                    way to showcase your products or brand in all
                                                    prints and promotional giveaways generating leads.
                                                </p>
                                                <Link to="/print-ar">
                                                    <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-5 fadeIN">
                                            <div>
                                                <img src={Group18677} className="img-fluid mb-5" />
                                            </div>
                                            <span className="editHeading"> OneXR</span>
                                            <div>
                                                <h2 className="pro-h2">LENS</h2>
                                                <p className="pro-p">
                                                    Interact in real-time with service reps via Augmented
                                                    reality video calls deterring
                                                    the miscellaneous costs.
                                                    Also, the AR 3D annotations, pointers, and doodles during

                                                    the phone calls are the added brownies! These features
                                                    enable you to get veteran
                                                    advice from experts right from home!
                                                </p>
                                                <Link to="/lens">
                                                    <button className="pro-btn">Get Started<i className="bi bi-arrow-right-short"></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="video-panel" style={{ backgroundImage: `url(${testimonial_image})` }}>
                    <span className='polarCircleBackground' style={{ backgroundImage: `url(${testimonial_polar_circle})` }}>
                        <div className="v-content mt-5">
                            <span>OneXR</span>
                            <h1>What industry experts think about Augray's ONE XR</h1>
                        </div>
                        <div className="video">
                            <video src={TestimonialVideo} controls playsInline muted controlsList="nodownload"></video>
                        </div>
                    </span>
                </div>

                <div className="company">
                    <div className="com-content">
                        <h5>We're working with</h5>
                        <h2>
                            World's most successful companies with trust
                        </h2>
                    </div>
                    <div className="com-marquee grab">
                        <div className="carousel">
                            <Slider {...CompaniesList}>
                                <div>
                                    <img src={caterpillar} />
                                </div>
                                <div><img src={dell} /></div>
                                <div><img src={philips} /></div>
                                <div><img src={samsung} /></div>
                                <div>
                                    <img src={luminous} />
                                </div>
                                <div><img src={havells} /></div>
                                <div><img src={spartan} /></div>
                                <div><img src={suba} /></div>
                                <div>
                                    <img src={rrkabels} />
                                </div>
                                <div>
                                    <img src={ashokleyland} />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="exploring">
                    <div className="row container" style={{ backgroundImage: `url(${worldmap})` }}>
                        <div className="expo-col col-12 col-lg-5">
                            <h4 className="expo-h4">
                                Exploring solutions & products to enhance your business
                                operations!
                            </h4>
                            <h3 className="expo-h3">
                                Ask about OneXR products & Solutions, pricing, implementation, or
                                anything else. Our representatives are standing by, ready to help.
                            </h3>
                            <div className='expoBtnDiv'><Link to="/requestDemo"><button className="expo-btn"><i className="bi bi-check2"></i> Get Started Now</button></Link></div>
                        </div>
                        <div className="expo-col2 col-12 col-lg-7">
                            <img src={expoimg} />
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default MainHomePage;
